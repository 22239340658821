import React, { useContext, useEffect, useState } from "react";
import "./IncreamentDecreament.css";
import { CartQuantity } from "../../../utils/apis/addToCart/addToCart";
import { Context } from "../../../utils/context";

const IncreamentDecreament = (props) => {
  const { signin, setCart } = useContext(Context);
  let { quantity, id } = props;

  useEffect(() => {
    setNumber(quantity);
  }, [quantity]);

  const [number, setNumber] = useState(0);
  const handleMinusClick = async () => {
    if (number > 0) {
      await CartQuantity(id, "minus", signin, setCart);
      setNumber(number - 1);
      if (props.updateQuantity) {
        props.updateQuantity(props.index, number - 1);
      }
    }
  };

  const handlePlusClick = async () => {
    await CartQuantity(id, "add", signin, setCart);
    setNumber(number + 1);
    if (props.updateQuantity) {
      props.updateQuantity(props.index, number + 1);
    }
  };

  const [showButtons, setShowButtons] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <>
      <section className="IncreamentDecreament">
        <div id="second-div" className="shopping-img-align">
          <div className=" add-cart-box">
            <button className="minus " onClick={() => handleMinusClick()}>
              -
            </button>
            <div className="quantity">{number}</div>
            <button className="add " onClick={() => handlePlusClick()}>
              +
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default IncreamentDecreament;
