import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import Login_Modal from "../Login_Modal/Login_Modal.js";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal.js";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { ForgetPassword } from "../../../../utils/apis/login/Login.js";
function Set_New_Password_Modal(props) {
  // const [modalLoginShow, setModalLoginShow] = useState(false);
  const [
    modalBottomSuccessfullShow,
    setModalBottomSuccessModalFalse,
  ] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // State for confirm password visibility

  const buttonStyle = {
    background: props.background,
    borderRadius: props.borderRadius,
    border: props.border,
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    color: props.color,
    padding: props.padding,
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  // Function to handle OTP submission
  const handleResetPasswordSubmit = () => {
    // Close OTP modal after timeout (e.g., 3 seconds)
    props.onHide();
    setModalBottomSuccessModalFalse(true);
    setTimeout(() => {
      setModalBottomSuccessModalFalse(false); // Hide success modal after timeout
      props.setModalLoginShow(true); // Show login modal after success modal hides
    }, 3000); // Adjust timeout as needed (e.g., 3000ms = 3 seconds)
  };

  //   ChangePassword
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      if (password === passwordAgain) {
        // console.log(data);
        const sendData = {
          new_password: data.new_password,
          email: props.getValues("email"),
        };
        // console.log(sendData);
        const res = await ForgetPassword(sendData);
        if (res?.success) {
          props.reset();
          reset();
          clearErrors();
          handleResetPasswordSubmit();
        } else {
          if (res?.message) {
            setError("re_password", {
              message: res?.message,
            });
          }
        }
      } else {
        setError("re_password", { message: "Password Does not match" });
      }
      //   console.log(data);
      //   const res = await ChangePassword(data);
      //   reset();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Set New Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0  ">
                  <div className="col-md-12">
                    <div className="heading-holder">
                      <p>
                        Your new password must be <span>Different</span> from
                        the previously used password.
                      </p>
                    </div>

                    <div className="log_in_form">
                      <Form>
                        <Form.Group
                          className="password-field-holder  mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Enter New Password*"
                            name="new_password"
                            {...register("new_password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message:
                                  "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.new_password,
                              "is-valid": getValues("new_password"),
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div
                            className="eye-icon-holder"
                            onClick={togglePasswordVisibility}
                          >
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon={passwordVisible ? faEye : faEyeSlash}
                            />
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="password-field-holder  mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type={confirmPasswordVisible ? "text" : "password"}
                            placeholder="Re-Enter Password*"
                            name="re_password"
                            {...register("re_password", {
                              required: "Re-Password is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.re_password,
                              "is-valid": getValues("re_password"),
                            })}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                          />
                          <div
                            className="eye-icon-holder"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon={confirmPasswordVisible ? faEye : faEyeSlash}
                            />
                          </div>
                        </Form.Group>
                        {password && (
                          <PasswordChecklist
                            rules={[
                              "minLength",
                              "specialChar",
                              "number",
                              "capital",
                              "match",
                            ]}
                            minLength={8}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => {}}
                            style={{
                              fontSize: "10px",
                              padding: "4px",
                              margin: "0",
                            }}
                          />
                        )}
                      </Form>

                      <div className="btn-holder">
                        <Common_Button
                          className="SubmitBtn green text"
                          text="RESET PASSWORD"
                          // onClick={handleResetPasswordSubmit}
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>

                      <div className="back_to_span">
                        <Link to={"/"}>
                          <p>Back to Home {">"}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Bottom_Successfull_Modal
        show={modalBottomSuccessfullShow}
        onHide={() => {
          setModalBottomSuccessModalFalse(false);
        }}
        text={"Password Reset Successfully!"}
      />
    </>
  );
}

export default Set_New_Password_Modal;
