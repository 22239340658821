import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  Row,
  Tab,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCartShopping,
  faHeart,
  faBars,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import "./Header.css";
import Login_Modal from "../CommonComponents/Common_Modal/Login_Modal/Login_Modal.js";
import Forget_Password_Modal from "../CommonComponents/Common_Modal/Forget_Password_Modal/Forget_Password_Modal";
import Register_Form_Modal from "../CommonComponents/Common_Modal/Register_Form_Modal/Register_Form_Modal";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal.js";
import Set_New_Password_Modal from "../CommonComponents/Common_Modal/Set_New_Password_Modal/Set_New_Password_Modal.js";
import OTP_Modal from "../CommonComponents/Common_Modal/OTP_Modal/OTP_Modal.js";
import SearchBar from "../CommonComponents/SearchBar/SearchBar";
import { Context } from "../../utils/context.js";
import { Categories } from "../../utils/apis/master/Master.js";
import { AllProducts, OrdersCounte } from "../../utils/apis/Product/Product.js";
import { updateCartCount } from "../../utils/apis/addToCart/addToCart.js";
import { useForm } from "react-hook-form";
import AutoSuggest from "react-autosuggest";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleSearchIconClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const [show, setShow] = useState(false);

  const handleCloseone = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // const [isScrolled, setIsScrolled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showNewPasswordModal, setNewPasswordModal] = useState(false);
  const [modalForgetShow, setForgetModalShow] = useState(false);
  const [modalCreatAccountShow, setCreatAccountModalShow] = useState(false);
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const [showOtpModal, setOtpModal] = useState(false);
  const treatments = [
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 2",
    },
    {
      name: "Essential Oil 1",
    },
  ];

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  // Close dropdown if click is outside
  const handleClickOutside = (e) => {
    if (!e.target.closest(".user-icon-container")) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [activeKey, setActiveKey] = useState(null);
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const handleNavItemClickdrop = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    setShowDropdown(!showDropdown);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [active, setActive] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleProductClick = () => {
    setShowDropdown(false);
  };
  // const location = useLocation();
  // const isActive = (path) => location.pathname === path;

  //   ----------------------------------------------------------------
  const {
    IMG_URL,
    signin,
    setSignin,
    Cookies,
    cart,
    setCart,
    wishlist,
  } = useContext(Context);

  const navigate = useNavigate();

  const [category, setCategory] = useState([]);
  const [max_price, setMax_price] = useState(0);
  const [populartags, setPopulartags] = useState([]);

  const getData = async () => {
    {
      const res = await Categories();
      setCategory(res.data);
      GetProductsFilter(res.data.find(() => true)?.id);
    }
  };

  const [products, setProducts] = useState([]);

  const GetProductsFilter = async (category_id = "") => {
    setActive(category_id);
    const res = await AllProducts(category_id, "", "", "", "");
    setProducts(res.data);
    // GetProductsFilter(res.data.data.find(() => true)?.id);
  };

  const [count, setcount] = useState({});

  const GetOrdersCounte = async () => {
    const res = await OrdersCounte();
    setcount(res.data);
    // GetProductsFilter(res.data.data.find(() => true)?.id);
  };

  useEffect(() => {
    getData();
    // GetProductsFilter();
    GetOrdersCounte();
  }, []);

  const [modalLogOutShow, setModelLogOutShow] = useState(false);

  const handleLogout = async () => {
    setModelLogOutShow(true);
    await updateCartCount(false, setCart);
    await setSignin(false);
    await Cookies.remove("mansa_nutri_security", { path: "/" });
    await navigate("/");
  };

  // Forget Password
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    register,
  } = useForm({
    email_or_contact: "",
  });

  // Autosuggest start
  const [valueauto, setValueauto] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSuggestionsFetchRequested = async ({ value }) => {
    if (typeof value === "string") {
      setValueauto(value.trim());
      try {
        const res = await AllProducts("", "", "", "", "", value.trim());
        setSuggestions(res?.data?.data || []);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSuggestionSelected = (_, { suggestion }) => {
    // console.log("Selected: " + suggestion.id);
    navigate(`/product-detail/${suggestion.id}`);
  };

  const getSuggestionValue = (suggestion) => suggestion.id;

  const renderSuggestion = (suggestion) => (
    <span>
      {suggestion.name} {suggestion.short_description}
    </span>
  );

  const inputProps = {
    placeholder: "Search",
    value: valueauto,
    onChange: (_, { newValue }) => {
      if (typeof newValue === "string") {
        setValueauto(newValue.trim());
      }
    },
  };
  // Autosuggest end

  return (
    <section className={`Header ${isScrolled ? "scrolled" : ""}`}>
      <div className="main-header Desktop">
        <Container>
          <div className="sub-header">
            <div className="min-header">
              {!signin && (
                <div className="log">
                  <p
                    className="sub-text me-1"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    LOGIN OR
                  </p>
                  <p
                    className="sub-text"
                    onClick={() => {
                      setCreatAccountModalShow(true);
                    }}
                  >
                    REGISTER
                  </p>
                </div>
              )}

              <Login_Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setForgetModalShow={() => setForgetModalShow(true)}
                setCreatAccountModalShow={() => setCreatAccountModalShow(true)}
                setSuccessfullModalShow={() => setSuccessfullModalShow(true)}
                setNewPasswordModal={() => setNewPasswordModal(true)}
                setOtpModal={() => setOtpModal(true)}
              />

              <Forget_Password_Modal
                show={modalForgetShow}
                onHide={() => setForgetModalShow(false)}
                setNewPasswordModal={() => setNewPasswordModal(true)}
                setOtpModalFalse={() => setOtpModal(false)}
                setOtpModal={() => setOtpModal(true)}
                control={control}
                setValue={setValue}
                handleSubmit={handleSubmit}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                register={register}
                errors={errors}
              />

              <Register_Form_Modal
                show={modalCreatAccountShow}
                onHide={() => {
                  setCreatAccountModalShow(false);
                }}
                setLoginModalShow={() => setModalShow(true)}
              />

              <Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => {
                  setSuccessfullModalShow(false);
                }}
                message={"Login Successfully!"}
              />

              <Successfull_Modal
                show={modalLogOutShow}
                onHide={() => {
                  setModelLogOutShow(false);
                }}
                message={"Log Out Successfully!"}
              />

              <OTP_Modal
                show={showOtpModal}
                setNewPasswordModal={() => setNewPasswordModal(true)}
                setOtpModalFalse={() => setOtpModal(false)}
                onHide={() => setOtpModal(false)}
                control={control}
                setValue={setValue}
                handleSubmit={handleSubmit}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                register={register}
                errors={errors}
              />

              <Set_New_Password_Modal
                show={showNewPasswordModal}
                onHide={() => setNewPasswordModal(false)}
                setModalLoginShow={() => setModalShow(true)}
                getValues={getValues}
                reset={reset}
              />

              <p className="sub-text">
                FREE SHIPPING ON ALL INDIA ORDERS {count?.count}+
              </p>
            </div>
          </div>
        </Container>
        <div className="main">
          {/* <Row className="main-header-content me-0 ms-0"> */}
          <div className="main-header-content ">
            {/* <Col xxl={7} sm={7} xs={7}> */}
            <div className="main-logo">
              <div className="logo-header">
                <Link to={"/"}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assests/logo/Mansa_Nutri_Logo.png"
                    }
                    className="logo"
                    alt="Mansa Nutri Logo"
                  />
                </Link>
              </div>
            </div>
            {/* </Col> */}
            {/* <Col xxl={5} sm={5} xs={5}> */}
            <div className="Main">
              {/* <div className="cart-section">
                <div className="search" >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Search here..."
                  className={`search-input ${isOpen ? "open" : ""}`}
                  style={{ display: isOpen ? "block" : "none" }}
                />


              </div> */}

              <div className="automain">
                <div
                  className={`react-autosuggest__container ${
                    isOpen ? "open" : ""
                  }`}
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  <AutoSuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      handleSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      handleSuggestionsClearRequested
                    }
                    onSuggestionSelected={handleSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    highlightFirstSuggestion={true}
                  />
                </div>
                <div className="search" onClick={handleSearchIconClick}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
              </div>

              <Link to="/cart">
                <div className="cart">
                  <FontAwesomeIcon icon={faCartShopping} />
                  <div className="number">
                    <p className="number">({cart?.length})</p>
                  </div>
                </div>
              </Link>
              <Link to="/wishlist">
                <div className="heart">
                  <FontAwesomeIcon icon={faHeart} />
                  <div className="number">
                    <p className="number mini-text">({wishlist?.length})</p>
                  </div>
                </div>
              </Link>
              {signin && (
                <div className="user-icon-container">
                  <Link to="#">
                    <div className="heart">
                      <FontAwesomeIcon icon={faUser} onClick={toggleDropdown} />
                      <div className="number mini-text"></div>
                      {dropdownVisible && (
                        <div className="drop-main">
                          <div className="main-drop">
                            <Link to="/account" onClick={closeDropdown}>
                              <p className="account">My Account</p>
                            </Link>
                            <Link onClick={handleLogout}>
                              <p className="account">Logout</p>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )}
            </div>
            {/* </Col> */}
            {/* </Row> */}
          </div>
        </div>
      </div>
      <div className="Desktop">
        <Nav
          className={`justify-content-center desktop-offcanvas ${
            isScrolled ? "scrolled" : ""
          }`}
        >
          <Nav.Link
            as={Link}
            to="/about"
            className={location.pathname === "/about" ? "active" : ""}
            onClick={handleNavItemClick}
          >
            ABOUT US
          </Nav.Link>

          {/* <Nav.Link
            as={Link}
            to="/product"
            className={location.pathname === "/product" ? "active" : ""}
            onClick={handleNavItemClick}
          >
            PRODUCTS
          </Nav.Link> */}
          <NavDropdown
            className={`deskdroppp ${
              location.pathname === "/product" ? "active" : ""
            }`}
            // onClick={handleNavItemClick}
            show={showDropdown}
            onToggle={handleNavItemClickdrop}
            title="PRODUCTS"
            id="basic-nav-dropdown"
          >
            <div>
              <Tab.Container id="left-tabs-example" activeKey={active}>
                <Row>
                  <Col xxl={3} xl={3} lg={3} md={12} className="dpq">
                    <div className="droprows1">
                      <Nav variant="pills" className="tabbmains flex-column">
                        {category?.map((val, index) => (
                          <Nav.Item
                            className="treattabitem"
                            key={index}
                            // onClick={() => GetProductsFilter(val.id)}
                            onClick={() => {
                              GetProductsFilter(val.id);
                              setActive(val.id);
                            }}
                          >
                            <Nav.Link
                              className="treattablink"
                              eventKey={`${val.id}`}
                            >
                              {val?.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}

                        {/* <Nav.Item className="treattabitem">
                          <Nav.Link className="treattablink" eventKey="second">
                            Essential Oil
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="treattabitem">
                          <Nav.Link className="treattablink" eventKey="third">
                            Phytochemicals
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                    </div>
                  </Col>
                  <Col xxl={9} xl={9} lg={8} md={12}>
                    <div className="droprows2">
                      <div className="scrollbar" id="style-3">
                        <Tab.Content>
                          {category?.map((val, index) => (
                            <Tab.Pane eventKey={`${val.id}`} key={index}>
                              <Row>
                                {products?.data?.map((product, index) => (
                                  <Col xxl={3} xl={3} lg={6} md={6} key={index}>
                                    <Link
                                      to={`/product-detail/${product.id}`}
                                      onClick={handleProductClick}
                                    >
                                      <p className="text">{product?.name}</p>
                                    </Link>
                                  </Col>
                                ))}
                              </Row>
                            </Tab.Pane>
                          ))}
                          {/* <Tab.Pane eventKey="second">
                            <Row>
                              <Col xxl={3} xl={3} lg={6} md={6}>
                                <Link to={``}>
                                  <p className="text">uiuihuo</p>
                                </Link>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <Row>
                              <Col xxl={3} xl={3} lg={6} md={6}>
                                <Link to={``}>
                                  <p className="text">uiuihuo</p>
                                </Link>
                              </Col>
                            </Row>
                          </Tab.Pane> */}
                        </Tab.Content>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </NavDropdown>
          <Nav.Link
            as={Link}
            to="/blog"
            className={location.pathname === "/blog" ? "active" : ""}
            onClick={handleNavItemClick}
          >
            BLOG
          </Nav.Link>

          <Nav.Link
            as={Link}
            to="/faq"
            className={location.pathname === "/faq" ? "active" : ""}
            onClick={handleNavItemClick}
          >
            FAQS
          </Nav.Link>

          <Nav.Link
            as={Link}
            to="/contact"
            className={location.pathname === "/contact" ? "active" : ""}
            onClick={handleNavItemClick}
          >
            CONTACT US
          </Nav.Link>
        </Nav>
      </div>

      <div className="main-header Mobile">
        <Container>
          <div className="sub-header">
            <div className="min-header">
              {!signin && (
                <div className="log">
                  <p
                    className="sub-text me-1"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    LOGIN OR
                  </p>
                  <p
                    className="sub-text"
                    onClick={() => {
                      setCreatAccountModalShow(true);
                    }}
                  >
                    REGISTER
                  </p>
                </div>
              )}
              <p className="sub-text">
                FREE SHIPPING ON ALL INDIA ORDERS {count?.count}+
              </p>
            </div>
          </div>
        </Container>
        <div className="main">
          <div className="main-logo">
            <div className="logo-header">
              <Link to={"/"}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/logo/Mansa_Nutri_Logo.png"
                  }
                  className="logo"
                  alt="Mansa Nutri Logo"
                />
              </Link>
            </div>
          </div>

          <Row className="main-header-content me-0 ms-0">
            <Col md={3} sm={3} xs={3}>
              <div className="toggle" onClick={handleShow}>
                <FontAwesomeIcon icon={faBars} />
              </div>
            </Col>

            <Col md={9} sm={9} xs={9}>
              <div className="Main">
                <div className="cart-section">
                  <div className="search">
                    {/* <FontAwesomeIcon icon={faMagnifyingGlass} /> */}
                  </div>
                  <div className="canva">
                    {["top"].map((placement, idx) => (
                      <SearchBar
                        key={idx}
                        placement={placement}
                        name={placement}
                      />
                    ))}
                  </div>
                </div>
                <Link to="/cart">
                  <div className="cart">
                    <FontAwesomeIcon icon={faCartShopping} />
                    <div className="number">
                      <p className="number">({cart?.length})</p>
                    </div>
                  </div>
                </Link>
                <Link to="/wishlist">
                  <div className="heart">
                    <FontAwesomeIcon icon={faHeart} />
                    <div className="number">
                      <p className="number">({wishlist?.length})</p>
                    </div>
                  </div>
                </Link>
                {signin && (
                  <div className="user-icon-container">
                    <Link to="#">
                      <div className="heart">
                        <FontAwesomeIcon
                          icon={faUser}
                          onClick={toggleDropdown}
                        />
                        <div className="number mini-text"></div>
                        {dropdownVisible && (
                          <div className="drop-main">
                            <div className="main-drop">
                              <Link to="/account" onClick={closeDropdown}>
                                <p className="account">My Account</p>
                              </Link>
                              <Link onClick={handleLogout}>
                                <p className="account">Logout</p>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="Mobile-Offcanvas">
        <Offcanvas
          show={show}
          onHide={handleCloseone}
          className="Mobile-Offcanvas"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end desktop-offcanvas">
              <Nav.Item>
                <Nav.Link as={Link} to="/" onClick={handleCloseone}>
                  HOME
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/about" onClick={handleCloseone}>
                  ABOUT US
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/blog" onClick={handleCloseone}>
                  BLOG
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/faq" onClick={handleCloseone}>
                  FAQS
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/contact" onClick={handleCloseone}>
                  CONTACT US
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Accordion
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k)}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordheaderr">
                      PRODUCTS
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <Tab.Container
                          id="left-tabs-example"
                          activeKey={active}
                        >
                          <Row>
                            <Col xxl={4} xl={4} lg={5} md={12} className="dpq">
                              <div className="droprows1">
                                <Nav
                                  variant="pills"
                                  className="tabbmains flex-column"
                                >
                                  {category?.map((val, index) => (
                                    <Nav.Item
                                      className="treattabitem"
                                      key={index}
                                      onClick={() => GetProductsFilter(val.id)}
                                    >
                                      <Nav.Link
                                        className="treattablink"
                                        eventKey={`${val.id}`}
                                      >
                                        {val?.name}
                                      </Nav.Link>
                                    </Nav.Item>
                                  ))}

                                  {/* <Nav.Item className="treattabitem">
                                    <Nav.Link
                                      className="treattablink"
                                      eventKey="first"
                                    >
                                      Carrier Oil
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="treattabitem">
                                    <Nav.Link
                                      className="treattablink"
                                      eventKey="second"
                                    >
                                      Essential Oil
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="treattabitem">
                                    <Nav.Link
                                      className="treattablink"
                                      eventKey="third"
                                    >
                                      Phytochemicals
                                    </Nav.Link>
                                  </Nav.Item> */}
                                </Nav>
                              </div>
                            </Col>
                            <Col xxl={8} xl={8} lg={7} md={12}>
                              <div className="droprows2 drppp">
                                <Tab.Content>
                                  {category?.map((val, index) => (
                                    <Tab.Pane
                                      eventKey={`${val.id}`}
                                      key={index}
                                    >
                                      <Row>
                                        {products?.data?.map(
                                          (product, index) => (
                                            <Col
                                              key={index}
                                              xxl={6}
                                              xl={6}
                                              lg={6}
                                              md={6}
                                              xs={6}
                                              sm={6}
                                            >
                                              <Link
                                                as={Link}
                                                to={`/product-detail/${product.id}`}
                                                onClick={handleCloseone}
                                              >
                                                <p className="treatname_text">
                                                  {product?.name}
                                                </p>
                                              </Link>
                                            </Col>
                                          )
                                        )}
                                      </Row>
                                    </Tab.Pane>
                                  ))}
                                  {/* <Tab.Pane eventKey="second">
                                    <Row>
                                      {treatments.map((treatment, index) => (
                                        <Col
                                          key={index}
                                          xxl={6}
                                          xl={6}
                                          lg={6}
                                          md={6}
                                          xs={6}
                                          sm={6}
                                        >
                                          <Link
                                            as={Link}
                                            to={`/product`}
                                            onClick={handleCloseone}
                                          >
                                            <p className="treatname_text">
                                              {treatment.name}
                                            </p>
                                          </Link>
                                        </Col>
                                      ))}
                                    </Row>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="third">
                                    <Row>
                                      {treatments.map((treatment, index) => (
                                        <Col
                                          key={index}
                                          xxl={6}
                                          xl={6}
                                          lg={6}
                                          md={6}
                                          xs={6}
                                          sm={6}
                                        >
                                          <Link
                                            as={Link}
                                            to={`/product`}
                                            onClick={handleCloseone}
                                          >
                                            <p className="treatname_text">
                                              {treatment.name}
                                            </p>
                                          </Link>
                                        </Col>
                                      ))}
                                    </Row>
                                  </Tab.Pane> */}
                                </Tab.Content>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Nav.Item>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </section>
  );
};

export default Header;
