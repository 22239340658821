import React from "react";
import "./Blog_card.css";
import { Link } from "react-router-dom";

function Blog_card({ image, title, date, id }) {
  return (
    <>
      <div className="Blog_card">
        <div className="img-holder">
          <img src={image} className="blog-card-image" />
        </div>
        <div className="overlay-text-content-holder">
          <div className="row me-0 ms-0  content-overlay">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8  col-sm-8  col-8 p-0">
              <div className="text-holder">
                <div className="overlay-color"></div>
                <h3 className="product-text">{title}</h3>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4  col-4 p-0">
              <Link to={`/blog-inner/${id}`}>
                <div className=" read-more-text-holder">
                  <div className="read-more-text">
                    <p>Read Now</p>
                    <span>{date}</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog_card;
