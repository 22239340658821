import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { useNavigation } from "react-navi"; // Assuming you're using Navi for navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import Common_Button from "../../Button_Common/Button_Common";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { Register } from "../../../../utils/apis/login/Login";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { RegxExpression } from "../../../../utils/common";
import {
  MobileOTPSend,
  MobileOTPVerify,
  NewMobileOTPSend,
} from "../../../../utils/apis/VerifyOTP/VerifyOTP";
import { postData } from "../../../../utils/api";
function Register_Form_Modal(props) {
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [otpsuccessModalShow, setOtpsuccessModalShow] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const navigation = useNavigation(); // Use navigation hook from Navi

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };
  const navigate = useNavigate();
  // useEffect(() => {
  //     if (modalSuccessfullShow) {
  //         setTimeout(() => {
  //             setSuccessfullModalShow(false);
  //             navigation.navigate('/');
  //         }, 3000);
  //     }
  // }, [modalSuccessfullShow, navigation]);
  useEffect(() => {
    let timer;
    if (modalSuccessfullShow) {
      timer = setTimeout(() => {
        setSuccessfullModalShow(false);

        navigate("/");
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [modalSuccessfullShow]);

  //   ------------------------------------------------------------------------------------------------
  const [showPasswordOne, setShowPasswordOne] = useState(false);

  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Cookies,
  } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      verifed: false,
    },
  });
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const onSubmit = async (data) => {
    setShimmerLoader(true);
    if (password === passwordAgain) {
      if (getValues("verifed")) {
        const res = await Register(data);
        if (res?.success) {
          setValue("verifed", false);
          setIsVerified(false);
          await Cookies.set("mansa_nutri_security", res?.data?.token);
          reset();
          clearErrors();
          setSignin(true);
          // await loginCart();
          props.onHide();
          setSuccessfullModalShow(true);
        } else {
          if (res?.message?.both) {
            setError("email", { message: res?.message?.both });
            setError("contact_no", { message: "" });
          }
          if (res?.message?.email) {
            setError("email", { message: res?.message?.email });
          }
          if (res?.message?.contact_no) {
            setError("contact_no", { message: res?.message?.contact_no });
          }
        }
      } else {
        setError("contact_no", { message: "Mobile No Not Verifyed" });
      }
    } else {
      setError("re_password", { message: "Password Does not match" });
    }
  };

  const [isVerified, setIsVerified] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleSendOTP = async () => {
    if (getValues("contact_no").length == 10) {
      clearErrors("contact_no");
      const res = await NewMobileOTPSend({
        contact_no: getValues("contact_no"),
      });

      if (res?.success) {
        setIsVerified(true);
        setDisabled(true);
        setIsResendEnabled(false);
        setTimer(120);
      } else {
        setError("contact_no", { message: res?.message });
      }
    } else {
      setError("contact_no", { message: "Enter Valid Mobile No." });
    }
  };

  const handleOTPVerify = async () => {
    if (getValues("otp").length == 4) {
      clearErrors("otp");
      const res = await MobileOTPVerify({
        contact_no: getValues("contact_no"),
        otp: getValues("otp"),
      });
    
      if (res?.success) {
        // setIsVerified(false);
        setValue("verifed", true);
        setOtpsuccessModalShow(true);
        // setIsVerified(false);
      } else {
        setError("otp", { message: res?.message });
      }
    } else {
      setError("otp", { message: "Enter Valid OTP" });
    }

    // Simulate verification
  };

  const deletOtp = async () => {
    if (getValues("contact_no")) {
      let sanitizedContactNo = getValues("contact_no").replace(/^(\+91)?/, "");
      // Remove any dashes (-)
      sanitizedContactNo = sanitizedContactNo.replace(/-/g, "");
      const res = await postData(`/without-login/verify-otp/delete`, {
        contact_no: sanitizedContactNo,
      });
      if (res?.success) {
        console.log("OTP DELETED", res?.data);
      } else {
        console.error("Failed to delete OTP:", res?.message);
      }
    } else {
      console.error("Valid Contact number is required");
      // setContactError("Contact number is required");
    }
  };

  useEffect(() => {
    reset();
    setValue("verifed", false);
    setIsVerified(false);
    setDisabled(false);
  }, [props.show]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      deletOtp();
      setIsResendEnabled(true);
      setDisabled(false);
    }
  }, [timer]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0">
                  <div className="col-md-12">
                    <div className="log_in_form">
                      <Form>
                        {/* <Form.Group className="mb-2" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Full Name"
                            {...register("first_name", {
                              required: "First name required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.first_name,
                              "is-valid": getValues("first_name"),
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.name.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Group> */}
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                            <Form.Group className="mb-2" controlId="">
                              <Form.Control
                                type="text"
                                placeholder="First Name"
                                {...register("first_name", {
                                  required: "First name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.first_name,
                                  "is-valid": getValues("first_name"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.name.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                            <Form.Group className="mb-2" controlId="">
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                                {...register("last_name", {
                                  required: "Last name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.last_name,
                                  "is-valid": getValues("last_name"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.name.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                            <Form.Group className="mb-2" controlId="">
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value: RegxExpression.email,
                                    message: "Invalid email address",
                                  },
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.email,
                                  "is-valid": getValues("email"),
                                })}
                              />
                            </Form.Group>{" "}
                            {errors?.email && (
                              <sup className="text-danger">
                                {errors?.email?.message}
                              </sup>
                            )}
                          </div>
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 mb-1">
                            <div className="verify-field-holder">
                              <Form.Group className="mb-2" controlId="">
                                <Form.Control
                                  type="text"
                                  className={classNames("", {
                                    "is-invalid": errors?.contact_no,
                                    "is-valid": isVerified,
                                  })}
                                  disabled={disabled}
                                  name="contact_no"
                                  placeholder="Enter Mobile Number"
                                  {...register("contact_no", {
                                    required: "Mobile Number is required",
                                    minLength: {
                                      value: 10,
                                      message:
                                        "Number should be at least 10 characters",
                                    },
                                  })}
                                  onKeyDown={(event) => {
                                    if (!RegxExpression.phone.test(event.key)) {
                                      if (event.key !== "Backspace") {
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  maxLength={10}
                                />
                              </Form.Group>{" "}
                              {!isVerified && (
                                <div
                                  className="verify-text-holder"
                                  onClick={handleSendOTP}
                                >
                                  <span>Send OTP</span>
                                </div>
                              )}
                            </div>

                            {errors?.contact_no && (
                              <sup className="text-danger">
                                {errors?.contact_no?.message}
                              </sup>
                            )}
                          </div>
                          {isVerified && (
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="verify-field-holder">
                                <Form.Group
                                  className="mb-2 field-holder"
                                  controlId=""
                                >
                                  <Form.Control
                                    type="text"
                                    className={classNames("", {
                                      "is-invalid": errors?.otp,
                                      "is-valid": getValues("verifed"),
                                    })}
                                    name="otp"
                                    disabled={getValues("verifed")}
                                    placeholder="Enter OTP"
                                    {...register("otp", {
                                      required: "Field is required",
                                      minLength: {
                                        value: 4,
                                        message:
                                          "Number should be at least 4 characters",
                                      },
                                    })}
                                    onKeyDown={(event) => {
                                      if (
                                        !RegxExpression.phone.test(event.key)
                                      ) {
                                        if (event.key !== "Backspace") {
                                          event.preventDefault();
                                        }
                                      }
                                    }}
                                    maxLength={4}
                                  />
                                </Form.Group>{" "}
                                <div className="otp-timer ">
                                  {isResendEnabled
                                    ? !getValues("verifed") && (
                                        <span
                                          className="text-green-holder"
                                          onClick={handleSendOTP}
                                        >
                                          Resend OTP
                                        </span>
                                      )
                                    : !getValues("verifed") && (
                                        <span>
                                          Enter OTP in {timer} seconds
                                        </span>
                                      )}
                                </div>
                                {!getValues("verifed") && (
                                  <div
                                    className="verify-text-holder"
                                    // onClick={() => {
                                    //   setOtpsuccessModalShow(true);
                                    // }}
                                    onClick={handleOTPVerify}
                                  >
                                    <span>Verify OTP</span>
                                  </div>
                                )}
                              </div>
                              {errors?.otp && (
                                <sup className="text-danger">
                                  {errors?.otp?.message}
                                </sup>
                              )}
                            </div>
                          )}
                        </div>
                        <Form.Group
                          className="password-field-holder mb-2"
                          controlId=""
                        >
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Enter Password*"
                            name="password"
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message:
                                  "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.password,
                              "is-valid": getValues("password"),
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div
                            className="eye-icon-holder"
                            onClick={togglePasswordVisibility}
                          >
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon={passwordVisible ? faEye : faEyeSlash}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="password-field-holder mb-2"
                          controlId=""
                        >
                          <Form.Control
                            type={passwordVisible2 ? "text" : "password"}
                            placeholder="Re-Enter Password*"
                            name="re_password"
                            {...register("re_password", {
                              required: "Re-Password is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.re_password,
                              "is-valid": getValues("re_password"),
                            })}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                          />
                          <div
                            className="eye-icon-holder "
                            onClick={togglePasswordVisibility2}
                          >
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon={passwordVisible2 ? faEye : faEyeSlash}
                            />
                          </div>
                        </Form.Group>{" "}
                        {password && (
                          <PasswordChecklist
                            rules={[
                              "minLength",
                              "specialChar",
                              "number",
                              "capital",
                              "match",
                            ]}
                            minLength={8}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => {}}
                            style={{
                              fontSize: "10px",
                              padding: "4px",
                              margin: "0",
                            }}
                          />
                        )}
                      </Form>

                      <div className="btn-holder">
                        <Common_Button
                          className="SubmitBtn green text"
                          text="Create"
                          onClick={handleSubmit(onSubmit)}
                          // disabled={getValues("verifed") ? false : true}
                          //   onClick={() => {
                          //     props.onHide();
                          //     setSuccessfullModalShow(true);
                          //   }}
                        />
                      </div>

                      <div className="create_account">
                        <div className="login_text">
                          <p>
                            Already have an account?
                            <span
                              className="login_text"
                              onClick={() => {
                                props.onHide();
                                props.setLoginModalShow(true);
                              }}
                            >
                              Login
                            </span>
                          </p>
                        </div>
                      </div>

                      {/* <div className="log_in_with">
                        <p>or register with social accounts</p>
                      </div>

                      <div className="social_img_holder">
                        <img
                          className="social_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/log_in/google.png"
                          }
                          alt="social_img"
                        />
                      </div> */}

                      <div className="back_to_span">
                        <Link to={"/"}>
                          <p>Back to Home {">"} </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Successfull_Modal
        show={modalSuccessfullShow}
        onHide={() => {
          setSuccessfullModalShow(false);
        }}
        message={"Signup Successfully!"}
      />

      <Successfull_Modal
        show={loginModalShow}
        onHide={() => {
          setLoginModalShow(false);
        }}
        message={"Login Successfully!"}
      />

      <Successfull_Modal
        show={otpsuccessModalShow}
        onHide={() => {
          setOtpsuccessModalShow(false);
        }}
        message={"OTP verified Successfully!"}
      />
    </>
  );
}

export default Register_Form_Modal;
