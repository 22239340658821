import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getBlogs = async (page = 1, category_id, popular_tags_id) => {
  try {
    return await getData(
      `/blog/show?page=${page}&category_id=${category_id}&popular_tags_id=${popular_tags_id}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getBlogDetails = async (id) => {
  try {
    return await getData(`/blog/show/${id}`);
  } catch (error) {
    console.error(error);
  }
};

// export const getOurMissions = async (category = null) => {
//   try {
//     return await getData(`/without-login/about-us/our-mission`);
//   } catch (error) {
//     console.error(error);
//   }
// };
