import React, { useContext, useEffect, useState } from "react";
import CartPage from "./CartPage/CartPage";
import SimilarProduct from "./SimilarProduct/SimilarProduct";
import { Context } from "../../utils/context";
import TrendingProducts from "../home/TrendingProducts/TrendingProducts";
const Cart = () => {
  const { cart } = useContext(Context);
  const [category_id, setCategoryId] = useState(0);

  // useEffect(() => {
  //   console.log(cart);
  // }, [cart]);
  return (
    <>
      <CartPage setCategoryId={setCategoryId} />
      {/* <SimilarProduct category_id={category_id} /> */}
      <TrendingProducts />
    </>
  );
};

export default Cart;
