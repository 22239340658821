import React, { useState, useEffect } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Container } from "react-bootstrap";
import "./Common.css";
import Address from "./Address";
import Payment from "./Payment";
import OrderReview from "./OrderReview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./AddressDetails.css";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import { useForm } from "react-hook-form";
function AddressDetails() {
  const [page, setPage] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const nextStep = () => {
    setPage((currPage) => currPage + 1);
  };

  const prevStep = () => {
    setPage((currPage) => currPage - 1);
  };

  // ----------------------------------------------------------------

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      payment_mode: "online",
    },
  });
  // ----------------------------------------------------------------

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Address
            nextStep={nextStep}
            heading={stepNames[0]}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            register={register}
            control={control}
            setError={setError}
            errors={errors}
          />
        );
      case 1:
        return (
          <Payment
            nextStep={nextStep}
            prevStep={prevStep}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            register={register}
            control={control}
            setError={setError}
            errors={errors}
          />
        );
      case 2:
        return (
          <OrderReview
            nextStep={nextStep}
            prevStep={prevStep}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            register={register}
            control={control}
            setError={setError}
            errors={errors}
          />
        );
      default:
        return null;
    }
  };

  const stepNames = ["Address Details", "Payment Details", "Order Review"];
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "CART", link: "/cart" },
    { text: "ADDRESS DETAILS", link: "/address" },
  ]);

  return (
    <>
      <Container>
        <Breadcrumbs items={breadcrumbItems} />
        <section className="SellerStepMain">
          <div className="ProgressBar">
            <ProgressBar
              filledBackground="#5B4020"
              height="3px"
              style={{ margin: "auto" }}
            >
              {stepNames.map((name, index) => (
                <Step key={index}>
                  {({ accomplished }) => (
                    <div
                      style={{
                        backgroundColor: index <= page ? "#5B4020" : "#FFF3EE",
                        color: index <= page ? "#fff" : "#000000",
                        border:
                          index <= page
                            ? "1px solid #5B4020"
                            : "1px solid #FFFFFF",
                        boxShadow:
                          index <= page ? "none" : "0px 0px 6px #00000029",
                      }}
                      className={`step ${index <= page ? "completed" : ""}`}
                    >
                      {index <= page && (
                        <p className="mb-0">
                          <FontAwesomeIcon icon={faCheck} />
                        </p>
                      )}
                      <div
                        className="step-name mini-text"
                        style={{
                          color: index <= page ? "#5B4020" : "#000000",
                        }}
                      >
                        {name}
                      </div>
                    </div>
                  )}
                </Step>
              ))}
            </ProgressBar>
          </div>

          <div className="userForm">
            <div className="step-Form-Container">{PageDisplay()}</div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default AddressDetails;
