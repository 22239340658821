import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getRandomCategory = async () => {
  try {
    return await getData(`/without-login/home/random-category`);
  } catch (error) {
    console.error(error);
  }
};

export const getTrendingProduct = async (category = null) => {
  try {
    return await getData(
      `/without-login/home/trending-product?category_id=${category}&page=1`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getBestEssential = async (category = null) => {
  try {
    return await getData(`/without-login/home/best-essential`);
  } catch (error) {
    console.error(error);
  }
};

export const getWhoWhereAre = async (category = null) => {
  try {
    return await getData(`/without-login/about-us/who-where-are`);
  } catch (error) {
    console.error(error);
  }
};

export const getBanners = async () => {
  try {
    return await getData(`/without-login/home/banner`);
  } catch (error) {
    console.error(error);
  }
};

// export const Country = async () => {
//   try {
//     return await getData(`/without-login/home/get-country`);
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const State = async (id) => {
//   try {
//     return await getData(`/without-login/home/get-state/${id}`);
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const City = async (id) => {
//   try {
//     return await getData(`/without-login/home/get-city/${id}`);
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const Cities = async () => {
//   try {
//     return await getData(`/without-login/allcity`);
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const Pincode = async (id) => {
//   try {
//     return await getData(`/without-login/home/get-pincode/${id}`);
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const Coupons = async () => {
//   try {
//     return await getData(`/without-login/home/coupons`);
//   } catch (error) {
//     console.error(error);
//   }
// };
