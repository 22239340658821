import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Yoga.css";
import { getBestEssential } from "../../../utils/apis/home";
import { Context } from "../../../utils/context";
const Yoga = () => {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getBestEssential();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="Yoga">
        <div className="Main">
          <div className="yoga-content">
            <img
              src={process.env.PUBLIC_URL + "/assests/Home/yoga/main.png"}
              className="yoga"
            />
            <img
              src={
                process.env.PUBLIC_URL + "/assests/Home/yoga/home _  yoga.png"
              }
              className="yoga2"
            />

              <div className="content">
            <Container>
                <h1 className="mid-title">
                  Best Essential Oils for Yoga and Meditation
                </h1>
                <div className="Main-Content">
                  {data?.map((data, index) => (
                    <div className="Meditations" key={index}>
                      <div className="circle">
                        <img src={IMG_URL + data?.logo} className="imag-1" />
                      </div>
                      <div className="meditation-content">
                        <h1 className="awake">{data?.name}</h1>
                        <p className="mini-text">
                          {" "}
                          {htmlToReactParser.parse(data?.description) || ""}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* <div className="Meditations">
                    <div className="circle">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/Home/yoga/imag1.png"
                        }
                        className="imag-1"
                      />
                    </div>
                    <div className="meditation-content">
                      <h1 className="awake">Awake & Mindful</h1>
                      <p className="mini-text">
                        Aromatic herbs and oils, integral to various religions
                        and cultures, enhance spiritual practices. Regular use
                        of essential oils can enrich your experience.
                      </p>
                    </div>
                  </div>

                  <div className="Meditations">
                    <div className="circle">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/Home/yoga/image2.png"
                        }
                        className="imag-1"
                      />
                    </div>
                    <div className="meditation-content">
                      <h1 className="awake">Oils for Yoga and Meditation</h1>
                      <p className="mini-text">
                        Enhance your yoga and meditation with our potent
                        essential oils like frankincense, rose, lavender,
                        ylang-ylang, sandalwood, peppermint, orange, jasmine,
                        clary sage, and more.
                      </p>
                    </div>
                  </div> */}
            </Container>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Yoga;
