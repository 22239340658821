import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const OrderCheckout = async (data) => {
  try {
    return await postData(`/order/order`, data);
  } catch (error) {
    console.error(error);
  }
};

// export const getCouponCode = async (code, amount) => {
//   try {
//     return await getData(
//       `/coupenCode/coupon-code?code=${code}&amount=${amount}`
//     );
//   } catch (error) {
//     // Capture the error message from the response
//     const message = error.response?.data?.message || "Invalid Coupon";
//     return { success: false, message };
//   }
// };
