import React from "react";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import About from "./components/About/About";
import Products from "./components/Products/Products";

import Privacy_Policy from "./components/Privacy_Policy/Privacy_Policy";
import Contact from "./components/Contact/Contact";
import Return_Policy from "./components/Return_Policy/Return_Policy";
import Blog from "./components/Blog/Blog";
import Faqs from "./components/Faqs/Faqs";
import Blog_Inner from "./components/Blog_Inner/Blog_Inner";

import ProductDetail from "./components/CommonComponents/ProductDetail/ProductDetail";

import Cart from "./components/Cart/Cart";
import Wishlist from "./components/Wishlist/Wishlist";
import Account from "./components/Account/Account";
import AddressDetails from "./components/AddressDetails/AddressDetails";
import Terms_And_Condition from "./components/Terms_And_Condition/Terms_And_Condition";
import YourOrders from "./components/YourOrders/YourOrders";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";

import Login_Activity from "./components/Login_Activity/Login_Activity.js";
import Change_Password from "./components/Change_Password/Change_Password.js";
import ReturnOrder from "./components/ReturnOrder/ReturnOrder.js";

//
import AppContext from "./utils/context";
import CancleOrder from "./components/CancleOrder/CancleOrder.js";
function App() {
  return (
    <AppContext>
      <ScrollToTop />
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/product" element={<Products />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-inner/:id" element={<Blog_Inner />} />
        <Route path="/faq" element={<Faqs />} />
        {/* <Route path="/terms-and-conditions" element={<Terms_And_Condition/>} /> */}
        <Route path="/privacy-policy" element={<Privacy_Policy />} />
        <Route path="/return-policy" element={<Return_Policy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/account" element={<Account />} />
        <Route path="/address" element={<AddressDetails />} />
        <Route path="/terms" element={<Terms_And_Condition />} />
        <Route path="/your-order" element={<YourOrders />} />
        <Route path="/login" element={<Login_Activity />} />
        <Route path="/change-password" element={<Change_Password />} />
        <Route path="/return-order/:id" element={<ReturnOrder />} />
        <Route path="/cancel-order/:id" element={<CancleOrder />} />
      </Routes>
      <Footer />
    </AppContext>
  );
}

export default App;
