import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const sendMobileOTP = async (contact_no) => {
  try {
    const res = await postData(`/without-login/otp/mobile-otp-send`, {
      contact_no,
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const verifyMobileOTP = async (data) => {
  try {
    const res = await postData(`/without-login/otp/mobile-verify-otp`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

// /forget-password-email-otp-send
export const ForgetPasswordEmailOtpSend = async (email) => {
  try {
    const res = await postData(
      `/without-login/otp/forget-password-email-otp-send`,
      { email }
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const verifyEmailOTP = async (data) => {
  try {
    const res = await postData(`/without-login/otp/email-verify-otp`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};
