import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal";
import Permision_Delete_Modal from "../CommonComponents/Common_Modal/Permision_Delete_Modal/Permision_Delete_Modal";
import { useNavigate } from "react-router-dom";
import {
  getSeletedCart,
  updateCartCount,
} from "../../utils/apis/addToCart/addToCart";
import { Context } from "../../utils/context";
import { useForm } from "react-hook-form";
import useRazorpay from "react-razorpay";
import { OrderCheckout } from "../../utils/apis/orderProcess/orderProcess";
const OrderReview = (props) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [deletepermissionShow, setdeletepermissionShow] = React.useState(false);

  //   ----------------------------------------------------------------

  const { IMG_URL, signin, setCart } = useContext(Context);
  const handlePlaceOrder = async () => {
    if (props.getValues("payment_mode") == "online") {
      await handleRazorpay(props.getValues("total"));
    } else if (props.getValues("payment_mode") == "cod") {
      //   console.log(props.getValues("payment_mode"));
      PlaceOrder();
    }
  };

  const PlaceOrder = async (payment_id = "") => {
    // console.log(props.getValues("payment_id"));
    // console.log(payment_id);
    const res = await OrderCheckout(props.getValues());
    // console.log(res);
    if (res?.success) {
      setModalShow(true);
      updateCartCount(signin, setCart);
      setTimeout(() => {
        setModalShow(false);
        navigate("/your-order");
      }, 2000);
    }
  };

  //
  const [Razorpay, openRazorpay] = useRazorpay();
  const handleRazorpay = async (amount) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(amount) * 100,
      currency: "INR",
      name: "Mansa Nutri",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        props.setValue("payment_id", res.razorpay_payment_id);
        PlaceOrder(res.razorpay_payment_id);
        // await setRazorId(res.razorpay_payment_id);
        // finalFunction(res.razorpay_payment_id);
        // console.log(res.razorpay_payment_id);
        // console.log(res, "ttttttttttttttttttttttttttttt");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  // ----------------------------------------------------------------

  const [selectedcart, setSeletecCart] = useState([]);
  const getData = async () => {
    const res = await getSeletedCart();
    setSeletecCart(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  //   const {
  //     control,
  //     register,
  //     handleSubmit,
  //     getValues,
  //     setValue,
  //     setError,
  //     reset,
  //     formState: { errors },
  //   } = useForm();

  useEffect(() => {
    TotalCalculations();
  }, [selectedcart]);

  const TotalCalculations = async () => {
    let total_amount = 0;
    let discount = 0;
    let total_tax = 0;
    let shipping = 0;
    let total = 0;

    for (const iterator of selectedcart) {
      if (iterator.status == 1) {
        // total_amount
        const amount = Number(iterator.price) * Number(iterator.quantity);
        total_amount = total_amount + amount;
        // total tax
        const tax = (amount / 100) * Number(iterator.tax_percentage);
        total_tax = total_tax + tax;
      }
    }

    total = total_amount + total_tax;

    total = total - discount;

    total = total + shipping;

    props.setValue("total_amount", total_amount);
    props.setValue("discount", discount);
    props.setValue("total_tax", total_tax);
    props.setValue("shipping", shipping);
    props.setValue("total", +total);
    props.reset(props.getValues());
  };

  return (
    <>
      <section className="OrderReview">
        <div className="Address">
          {/* <Form.Check type="checkbox" label="Select All" /> */}
          {/* <div className="place-order mt-4"> */}
          <div className="SellerStepMain">
            <div className="apply_now_btn">
              <Button_Common
                onClick={props.prevStep}
                className="back-btn me-3 text brown"
                text="BACK"
              >
                Back
              </Button_Common>
            </div>
          </div>
          {/* </div> */}
          <Row className="justify-content-center mt-5">
            <Col xxl={7} xl={8} lg={12} md={12}>
              {selectedcart?.map((item, index) => (
                <div className="Deatils-address" key={index}>
                  {/* <Form.Check type="checkbox" /> */}
                  <div className="OrderDetails">
                    <div className="Details">
                      <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} xs={12} sm={12}>
                          <div className="main-cart">
                            <div className="image-product">
                              <img
                                src={IMG_URL + item?.image}
                                alt="product"
                                className="payment-logo"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xxl={7} xl={8} lg={7} md={7} xs={12} sm={12}>
                          <div className="content">
                            <Row>
                              <Col xxl={5} xl={4} lg={4} md={4} xs={4} sm={4}>
                                <p className="mini-text">Product Name:</p>
                              </Col>
                              <Col xxl={7} xl={8} lg={8} md={8} xs={8} sm={8}>
                                <p className="mini-text">{item?.name}</p>
                              </Col>
                              <Col xxl={5} xl={4} lg={4} md={4} xs={4} sm={4}>
                                <p className="mini-text">Quantity:</p>
                              </Col>
                              <Col xxl={7} xl={8} lg={8} md={8} xs={8} sm={8}>
                                <p className="mini-text">
                                  {item?.v_quantity}
                                  {item?.unit}
                                </p>
                              </Col>
                              <Col xxl={5} xl={4} lg={4} md={4} xs={4} sm={4}>
                                <p className="mini-text">Qty Of Packs:</p>
                              </Col>
                              <Col xxl={7} xl={8} lg={8} md={8} xs={8} sm={8}>
                                <p className="mini-text">{item?.quantity}</p>
                              </Col>
                              <Col xxl={5} xl={4} lg={4} md={4} xs={4} sm={4}>
                                <p className="mini-text">Price:</p>
                              </Col>
                              <Col xxl={7} xl={8} lg={8} md={8} xs={8} sm={8}>
                                <p className="mini-text">{item?.price}</p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        {/* <Col xxl={2} xl={1} lg={2} md={1} xs={12} sm={12}>
                          <div className="Delete-order">
                            <div className="delete-main">
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="icon"
                                onClick={() => setdeletepermissionShow(true)}
                              />
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                </div>
              ))}
            </Col>
            <Col xxl={5} xl={4} lg={7} md={10}>
              <div className="Order-payment">
                <div className="total-amount">
                  <Row>
                    <Col xxl={4} xl={6} lg={4} md={4}>
                      <p className="mini-text">
                        Price Item({selectedcart?.length}):
                      </p>
                    </Col>
                    <Col xxl={8} xl={6} lg={8} md={8}>
                      <p className="mini-text">
                        ₹ {props.getValues("total_amount")}
                      </p>
                    </Col>
                    <Col xxl={4} xl={6} lg={4} md={4}>
                      <p className="mini-text">Discount:</p>
                    </Col>
                    <Col xxl={8} xl={6} lg={8} md={8}>
                      <p className="mini-text">
                        - ₹ {props.getValues("discount")}
                      </p>
                    </Col>
                    <Col xxl={4} xl={6} lg={4} md={4}>
                      <p className="mini-text">Payment Method:</p>
                    </Col>
                    <Col xxl={8} xl={6} lg={8} md={8}>
                      <p className="mini-text">
                        {props.getValues("payment_mode")}
                      </p>
                    </Col>
                    <Col xxl={4} xl={6} lg={4} md={4}>
                      <p className="mini-text">Delivery charges:</p>
                    </Col>
                    <Col xxl={8} xl={6} lg={8} md={8}>
                      <p className="mini-text">
                        {/* {props.getValues("payment_mode")} */}0
                      </p>
                    </Col>
                    <Col xxl={4} xl={6} lg={4} md={4}>
                      <p className="mini-text">Address:</p>
                    </Col>
                    <Col xxl={8} xl={6} lg={8} md={8}>
                      <p className="mini-text">
                        {props.getValues("address.add_type")}{" "}
                        {props.getValues("address.address")}{" "}
                        {props.getValues("address.landmark")} -{" "}
                        {props.getValues("address.zip_code")}
                      </p>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4}>
                      <p className="price-text ">Total amount:</p>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8}>
                      <p className="price-text ">
                        ₹ {props.getValues("total")}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="place-order mt-4">
                  <Button_Common
                    className="order green"
                    text="PLACE ORDER"
                    onClick={handlePlaceOrder}
                  />
                </div>

                <Successfull_Modal
                  message="Your Order Has Been Placed Successfully!"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </Col>
          </Row>
          {/* <div className="  apply_now_btn">
                        <Button_Common onClick={props.prevStep} className="back-btn me-3 text brown" text="BACK">
                            Back
                        </Button_Common>

                        <Button_Common
                            type="button"
                            onClick={props.nextStep}
                            className="tabs-btn text green"
                            text="SAVE & NEXT"
                        >
                            Next
                        </Button_Common>

                       
                    </div> */}
          <Permision_Delete_Modal
            show={deletepermissionShow}
            onHide={() => setdeletepermissionShow(false)}
          />
        </div>
      </section>
    </>
  );
};

export default OrderReview;
