import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Button_Common/Button_Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import "./Modals.css";
import { Login } from "../../../../utils/apis/login/Login";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import { loginCart } from "../../../../utils/apis/addToCart/addToCart";
const Email_and_Password = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const { signin, setSignin, Cookies } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await Login(data);
    if (res?.success) {
      await Cookies.set("mansa_nutri_security", res?.data?.token);
      reset();
      clearErrors();
      setSignin(true);
      await loginCart();
      props.handleLoginClick();
    } else {
      if (res?.message) {
        setError("email_or_contact", { message: res?.message });
        setError("password", "");
      }
    }
  };
  return (
    <>
      <Form>
        <Form.Group className="mb-2" controlId="">
          <Form.Control
            type="text"
            placeholder="Email / Mobile Number"
            name="email_or_contact"
            {...register("email_or_contact", {
              required: "Credentials required",
            })}
            className={classNames("", {
              "is-invalid": errors?.email_or_contact,
              "is-valid": getValues("email_or_contact"),
            })}
          />
        </Form.Group>{" "}
        {errors?.email_or_contact && (
          <sup className="text-danger">{errors?.email_or_contact?.message}</sup>
        )}
        <Form.Group className="password-field-holder mb-2" controlId="">
          <Form.Control
            type={passwordVisible ? "text" : "password"}
            placeholder="Enter Password*"
            name="password"
            {...register("password", {
              required: "Password is required",
            })}
            className={classNames("", {
              "is-invalid": errors?.password,
              "is-valid": getValues("password"),
            })}
          />
          <div className="eye-icon-holder" onClick={togglePasswordVisibility}>
            <FontAwesomeIcon
              className="eye-icon"
              icon={passwordVisible ? faEye : faEyeSlash}
            />
          </div>
        </Form.Group>
        <Form.Text
          className="Forgot_password"
          onClick={() => {
            props.setForgetModalShow(true);
            props.onHide();
          }}
        >
          Forgot password?
        </Form.Text>
        <div className="btn-holder">
          <Common_Button
            className="SubmitBtn green text"
            text="Login"
            onClick={handleSubmit(onSubmit)}
            // onClick={handleLoginClick}
          />
        </div>
        <div className="create_account ">
          <div className="login_text">
            <p>
              You don't have an account?
              <span
                className="login_text"
                onClick={() => {
                  props.setCreatAccountModalShow(true);
                  props.onHide();
                }}
              >
                Create account
              </span>
            </p>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Email_and_Password;
