import React from "react";
import "./Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common";
import { Context } from "../../../utils/context";
import { useContext, useEffect, useState } from "react";
import { getBanners } from "../../../utils/apis/home";
import { useNavigate } from "react-router-dom";
function Banner() {
  const navigate = useNavigate();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getBanners();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  const banners = [
    {
      image: process.env.PUBLIC_URL + "/assests/Home/Banner/banner-home.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Home/Banner/banner-home.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Home/Banner/banner-home.png",
    },
  ];
  return (
    // Home Banner Started

    <section className="Home-Banner">
      <div className="container-fluid p-0">
        <Swiper
         cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper"
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="image-home">
                <img src={IMG_URL + item.image} className="banner-image" />
                <div className="content-banner">
                  <div className="main-content">
                    <p className="text">{item?.quote}</p>
                    <p className="title">
                      {item?.name}
                      {/* Purest Quality of <br></br>Essentials Oil */}
                    </p>
                    <Button_Common
                      text="Explore"
                      className="explore mt-2"
                      onClick={() => navigate("/product")}
                    />
                  </div>
                </div>
              </div>
              <div className="image-overlay"></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
    //  Home Banner End
  );
}

export default Banner;
