import React, { useState, useEffect, useContext } from "react";
import "./ProductCards.css";
import Heart_Component from "../Heart_Component/Heart_Component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Bottom_Successfull_Modal from "../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { Context } from "../../../utils/context";

const ProductCards = ({ image, title, price, id = 0 }) => {
  const navigate = useNavigate();
  const { wishlist, IMG_URL } = useContext(Context);
  // const [showModal, setShowModal] = useState(false);
  // const handleShowModal = () => setShowModal(true);
  // const handleCloseModal = () => setShowModal(false);
  // useEffect(() => {
  //   let timer;
  //   if (showModal) {
  //     timer = setTimeout(() => {
  //       setShowModal(false);
  //       navigate("/product-detail");
  //     }, 1000);
  //   }

  //   return () => clearTimeout(timer);
  // }, [showModal, navigate]);

  return (
    <>
      <section className="ProductCards">
        <div className="Product-Card">
          <div className="Product-Image">
            <Link to={`/product-detail/${id}`}>
              <img src={image} className="card-images" alt={title} />
            </Link>
          </div>
          <div className="heart-icon">
            <Heart_Component
              status={wishlist?.some((item) => item.product_id === id)}
              product_id={id}
            />
          </div>
          <Link to={`/product-detail/${id}`}>
            <div className="content-product">
              <p className="product-text">{title}</p>
            </div>
            <div
              className="price-product"
              onClick={() => {
                navigate(`/product-detail/${id}`);
                // setShowModal(id);
              }}
            >
              <p className="price-text"> ₹ {price} </p>
              <FontAwesomeIcon icon={faCartShopping} className="icon" />
            </div>
          </Link>
        </div>
        <Bottom_Successfull_Modal
          // show={showModal}
          // onHide={handleCloseModal}
          text="Item added to cart successfully!"
        />
      </section>
    </>
  );
};

export default ProductCards;
