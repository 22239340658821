import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getFAQCategory = async () => {
  try {
    return await getData(`/without-login/all-faq-categories`);
  } catch (error) {
    console.error(error);
  }
};

export const getFAQs = async (id) => {
  try {
    return await getData(`/without-login/allfaq/${id}`);
  } catch (error) {
    console.error(error);
  }
};
