import React from "react";
import "./Common_Banner.css";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Banner_Heading_Text from "../Banner_Heading_Text/Banner_Heading_Text";

function Common_Banner({ bannerImg, headingText, breadcrumbItems }) {
  return (
    <>
      <div className="Common_Banner">
        <img src={bannerImg} className="about-banner" />
        <div className="container">
          <div className="content-text-holder">
            <Banner_Heading_Text
              headingText={headingText}
              breadcrumbItems={breadcrumbItems}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Common_Banner;
