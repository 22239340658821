import React from "react";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./ProductContent.css";
const ProductContent = (props) => {
  const { data, IMG_URL, htmlToReactParser } = props;
  return (
    <>
      <section className="ProductContent">
        <Container>
          <h1 className="title">Specifications Sheet</h1>
          <div className="Mai-Content">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <p className="price-text">Properties</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <p className="price-text">Safety Information</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="tab-content">
                    <p className="mini-text">
                      {htmlToReactParser.parse(data?.properties) || ""}
                      {/* Balsam Peru is a resinous substance that has been used in
                      the perfume and incense industry for many centuries. It is
                      extracted from the trunk of the Myroxylon balsamum tree,
                      which is native to Central and South America. The sap of
                      the tree is harvested by making incisions in the bark,
                      allowing the resin to flow out naturally. This process
                      does not harm the tree and is therefore considered a
                      sustainable harvesting method. Balsam Peru has a warm,
                      sweet, and balsamic odor, which is why it is commonly used
                      in perfumery. It is a popular ingredient in oriental and
                      woody fragrances, as well as in incense blends. Its
                      versatility also makes it a valuable addition in the
                      formulation of toiletries such as soaps and shampoos. The
                      areas of extraction for Balsam Peru are primarily in the
                      Central and South American regions, including countries
                      such as El Salvador, Guatemala, and Honduras. The absolute
                      extracted from the resinoid by alcohol extraction is known
                      as Balsam Peru Absolute, which is a highly concentrated
                      form of the resin. */}
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="tab-content">
                    <p className="mini-text">
                      {htmlToReactParser.parse(data?.safety_information) || ""}
                      {/* Balsam Peru is a resinous substance that has been used in
                      the perfume and incense industry for many centuries. It is
                      extracted from the trunk of the Myroxylon balsamum tree,
                      which is native to Central and South America. The sap of
                      the tree is harvested by making incisions in the bark,
                      allowing the resin to flow out naturally. This process
                      does not harm the tree and is therefore considered a
                      sustainable harvesting method. Balsam Peru has a warm,
                      sweet, and balsamic odor, which is why it is commonly used
                      in perfumery. It is a popular ingredient in oriental and
                      woody fragrances, as well as in incense blends. Its
                      versatility also makes it a valuable addition in the
                      formulation of toiletries such as soaps and shampoos. The
                      areas of extraction for Balsam Peru are primarily in the
                      Central and South American regions, including countries
                      such as El Salvador, Guatemala, and Honduras. The absolute
                      extracted from the resinoid by alcohol extraction is known
                      as Balsam Peru Absolute, which is a highly concentrated
                      form of the resin. */}
                    </p>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ProductContent;
