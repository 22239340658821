import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Common_Button from "../../Button_Common/Button_Common";
import { verifyEmailOTP } from "../../../../utils/apis/otp/otp";

function OTP_Modal(props) {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      otp: new Array(4).fill(""),
    },
  });
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      setValue(`otp.${index}`, value);
      if (getValues("otp").length == 4) {
        clearErrors();
      }
      if (value !== "" && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // const handleKeyDown = (e, index) => {
  //   if (e.key === "Backspace" && !getValues(`otp.${index}`) && index > 0) {
  //     setValue(`otp.${index - 1}`, "");
  //     inputRefs.current[index - 1].focus();
  //   }
  // };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !getValues(`otp.${index}`) && index > 0) {
      setValue(`otp.${index - 1}`, "");
      inputRefs.current[index - 1].focus();
    } else if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    const otp = data.otp.join("");
    // console.log("OTP Length:", otp.length);

    if (otp.length === 4) {
      const data = {
        email: props.getValues(`email`),
        otp: otp,
      };
      // console.log("OTP Submitted:", data);
      const res = await verifyEmailOTP(data);
      if (res?.success) {
        reset();
        props.setOtpModalFalse();
        props.setNewPasswordModal();
      } else {
        setError("otp", { message: "Invalid OTP" });
      }
    } else {
      setError("otp", { message: "Enter Valid OTP" });
    }
    // const res = await Register(data);
    // if (res?.success) {
    //   reset();
    //   clearErrors();
    //   props.onHide();
    // } else {
    //   if (res?.message?.both) {
    //     setError("email", { message: res?.message?.both });
    //     setError("contact_no", { message: "" });
    //   }
    //   if (res?.message?.email) {
    //     setError("email", { message: res?.message?.email });
    //   }
    //   if (res?.message?.contact_no) {
    //     setError("contact_no", { message: res?.message?.contact_no });
    //   }
    // }
  };
  return (
    <>
      <Modal
        {...props}
        size="sm"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              {/* Login Form */}
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0">
                  <div className="col-xl-12  col-md-12  col-sm-12">
                    <div className="log_in_form">
                      <div className="heading-holder">
                        <p>OTP has been Sent to the Email / Mobile Number</p>
                      </div>

                      <Form>
                        <div className="otp-holder">
                          {[...Array(4)].map((_, index) => (
                            <div className="otp-text-holder" key={index}>
                              <Form.Group
                                className="mb-3"
                                controlId={`otp-${index}`}
                              >
                                <Controller
                                  name={`otp.${index}`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <Form.Control
                                      type="text"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        handleChange(e.target.value, index);
                                      }}
                                      onKeyDown={(e) => handleKeyDown(e, index)}
                                      ref={(el) =>
                                        (inputRefs.current[index] = el)
                                      }
                                      maxLength="1"
                                    />
                                  )}
                                />
                              </Form.Group>
                            </div>
                          ))}

                          {/* <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div> */}
                        </div>
                        {errors?.otp && (
                          <sup className="text-danger">
                            {errors?.otp?.message}
                          </sup>
                        )}
                        <div className="btn-holder">
                          <Common_Button
                            className="SubmitBtn green text"
                            text="Verify OTP"
                            onClick={handleSubmit(onSubmit)}
                            // onClick={handleLoginClick}
                          />
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OTP_Modal;
