import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import "./Account.css";
import { Link } from "react-router-dom";
const Account = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "HOME", link: "/"},
        {text: "ACCOUNT", link: "/account", className: "active"},
        ,
    ]);
    return (
        <>
        <Container>
        <Breadcrumbs items={breadcrumbItems} />
        </Container>
        
            <section className="Account">

                <Container>
                   
                    <div className="account-main">
                        <Row>
                            <Col xxl={3} xl={3} lg={3} md={6} sm={6}>
                            <Link to="/your-order">
                                <div className="accounts-class">
                                    <div className="image-account">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Account/a1.png"}
                                            className="count-logo"
                                            alt="Mansa Nutri Logo"
                                        />
                                        <div className="overlay">
                                            <p className="product-detail">Your Orders</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={6} sm={6}>
                            <Link to="/login">
                            <div className="accounts-class">
                                    <div className="image-account">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Account/a2.png"}
                                            className="count-logo"
                                            alt="Mansa Nutri Logo"
                                        />
                                        <div className="overlay">
                                            <p className="product-detail">Login Activity</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                              
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={6} sm={6}>
                            <Link to="/change-password">
                                <div className="accounts-class">
                                    <div className="image-account">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Account/a3.png"}
                                            className="count-logo"
                                            alt="Mansa Nutri Logo"
                                        />
                                        <div className="overlay">
                                            <p className="product-detail">Change Password</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={6} sm={6}>
                            <Link to="/contact">
                                <div className="accounts-class">
                                    <div className="image-account">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Account/a4.png"}
                                            className="count-logo"
                                            alt="Mansa Nutri Logo"
                                        />
                                        <div className="overlay">
                                            <p className="product-detail">Contact Us</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Account;
