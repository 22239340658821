import React, { useContext, useEffect, useState } from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppSetup } from "../../utils/apis/common/Common";
import { Context } from "../../utils/context";
import { SocialMedia } from "../../utils/apis/master/Master";
const Footer = () => {
  // AppSetup
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);

  const getData = async () => {
    const res = await AppSetup();
    setData(res.data);

    {
      const res = await SocialMedia();
      setSocialMedia(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {/* Footer Section Started */}

      <section className="Footer">
        <Container>
          <Row className="main">
            <Col xxl={3} xl={3} lg={6} md={12} sm={12} className="mt-2">
              <div>
                <h1 className="sub-text">Contact Us</h1>
                <p className="text">
                  Email:
                  <br />
                  {data?.email}
                </p>
                <p className="text">Phone: +91 {data?.contact_no}</p>
                <p className="text">Address:{data?.address}</p>
              </div>
            </Col>
            <Col
              xxl={3}
              xl={3}
              lg={6}
              md={12}
              sm={12}
              className="footer-link-text mt-2"
            >
              <div>
                <h1 className="sub-text">Information</h1>
                <Link to="/about">
                  <p className="text">About Us</p>
                </Link>
                <Link to="/product">
                  <p className="text">All Products</p>
                </Link>
                <Link to="/blog">
                  <p className="text">Blogs</p>
                </Link>
                <Link to="/terms">
                  <p className="text">Terms And Condition</p>
                </Link>
              </div>
            </Col>
            <Col
              xxl={3}
              xl={3}
              lg={6}
              md={12}
              sm={12}
              className="footer-link-text mt-2"
            >
              <div>
                <h1 className="sub-text">Customer Service</h1>
                <Link to="/contact">
                  <p className="text">Contact</p>
                </Link>

                <Link to="/faq">
                  <p className="text">FAQ</p>
                </Link>

                <Link to="/terms">
                  <p className="text">Terms And Condition</p>
                </Link>

                <Link to="/return-policy">
                  <p className="text">Return Policy</p>
                </Link>

                <Link to="/privacy-policy">
                  <p className="text">Privacy Policy</p>
                </Link>
              </div>
            </Col>
            <Col
              xxl={3}
              xl={3}
              lg={6}
              md={12}
              sm={12}
              className="footer-link-text mt-2"
            >
              <div>
                <h1 className="sub-text">Follow Us</h1>
                {socialMedia?.map((social, index) => (
                  <>
                    <Link to={social?.link} target="_black">
                      <div className="social-icon-holder">
                        <p className="text" key={index}>
                          <img
                            src={IMG_URL + social?.logo}
                            className="icon-img ficnn2 me-2"
                            alt="icon-img"
                          />{" "}
                          {social?.name}
                        </p>
                      </div>
                    </Link>
                  </>
                ))}
                {/* <p className="text">Twitter</p>
                <p className="text">LinkedIn</p>
                <p className="text">YouTube</p> */}
              </div>
            </Col>
          </Row>
          <div className="border-footer"></div>
          <p className="text copy text-center">
            Copyright ©2024 All Rights Reserved. Developed by Profcyma.
          </p>
        </Container>
      </section>

      {/* Footer Section End */}
    </>
  );
};

export default Footer;
