import React, { useContext, useEffect, useState } from "react";
import "./Products.css";
import Common_Banner from "../CommonComponents/Common_Banner/Common_Banner";
import ProductCards from "../CommonComponents/ProductCards/ProductCards";
import Product_Filter from "./Product_Filter/Product_Filter";
import Banner_Heading_Text from "../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import { Col, Row } from "react-bootstrap";
import { Categories, MaxPrice } from "../../utils/apis/master/Master";
import { AllProducts } from "../../utils/apis/Product/Product";
import { Context } from "../../utils/context";

// const products = [
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p1.png",
//     title: "Black Pepper Essential Oils ",
//     price: "00.00",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p2.png",
//     title: "Bergamot Essential Oil",
//     price: "00.00",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
//     title: "Bitter Orange Oil ",
//     price: "00.00",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p4.png",
//     title: "Carrot Seed Oil ",
//     price: "00.00",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p1.png",
//     title: "Black Pepper Essential Oils ",
//     price: "00.00",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p2.png",
//     title: "Bergamot Essential Oil",
//     price: "00.00",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
//     title: "Bitter Orange Oil ",
//     price: "00.00",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assests/ProductCards/p4.png",
//     title: "Carrot Seed Oil ",
//     price: "00.00",
//   },
// ];
function Products() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "PRODUCTS", link: "/product", className: "active" },
    ,
  ]);

  //   ----------------------------------------------------------------

  const { IMG_URL } = useContext(Context);

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [max_price, setMax_price] = useState(0);
  const [populartags, setPopulartags] = useState([]);

  const getData = async () => {
    {
      const res = await Categories();
      setCategory(res.data);
      let a = [];
      for (const data of res.data) {
        a.push(data?.id);
      }
      setSelectedCategory(a);
    }
    {
      const res = await MaxPrice();
      setMax_price(res.data);
    }
  };

  const [products, setProducts] = useState([]);

  const GetProductsFilter = async (
    category_id = "",
    end_price = max_price,
    page = 1
  ) => {
    const res = await AllProducts(category_id, "", "", end_price, page);
    setProducts(res.data);
  };
  useEffect(() => {
    getData();
    GetProductsFilter();
  }, []);

  useEffect(() => {
    GetProductsFilter(JSON.stringify(selectedCategory));
  }, [selectedCategory]);

  return (
    <>
      <div className="Products">
        <div className="container">
          <Row>
            <Col xxl={3} xl={3} lg={3}>
              <Banner_Heading_Text
                headingText={"Products"}
                breadcrumbItems={breadcrumbItems}
              />
              <div className="filter-section">
                <Product_Filter
                  category={category}
                  max_price={max_price}
                  GetProductsFilter={GetProductsFilter}
                  setSelectedCategory={setSelectedCategory}
                  selectedCategory={selectedCategory}
                />
              </div>
            </Col>
            <Col xxl={8} xl={8} lg={8}>
              <div className="products-card-section">
                <Row>
                  {products?.data?.map((item, index) => (
                    <Col
                      xxl={4}
                      xl={4}
                      lg={6}
                      md={6}
                      xs={12}
                      sm={6}
                      key={index}
                    >
                      <ProductCards
                        id={item?.id}
                        image={IMG_URL + item.image}
                        title={item.name}
                        price={item?.quantity.find(() => true)?.price}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Products;
