import React from "react";
import { Container } from "react-bootstrap";
import "./RelatedProducts.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ProductCards from "../../ProductCards/ProductCards";

const RelatedProducts = (props) => {
  const { related_products, IMG_URL } = props;
  //   const related = [
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductCards/r1.png",
  //       title: "Black Pepper Essential Oils ",
  //       price: "00.00",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductCards/r2.png",
  //       title: "Bergamot Essential Oil",
  //       price: "00.00",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductCards/r3.png",
  //       title: "Bitter Orange Oil ",
  //       price: "00.00",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductCards/r4.png",
  //       title: "Carrot Seed Oil ",
  //       price: "00.00",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductCards/r1.png",
  //       title: "Black Pepper Essential Oils ",
  //       price: "00.00",
  //     },
  //     {
  //       image: process.env.PUBLIC_URL + "/assests/ProductCards/r2.png",
  //       title: "Bergamot Essential Oil",
  //       price: "00.00",
  //     },
  //   ];
  return (
    <>
      <section className="RelatedProducts">
        <Container>
          <h1 className="title">Related Products</h1>
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              380: {
                slidesPerView: 1,
              },
              485: {
                slidesPerView: 2,
              },
              575: {
                slidesPerView: 2,
              },

              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
              1440: {
                slidesPerView: 4,
              },
              2000: {
                slidesPerView: 4,
              },
            }}
          >
            {related_products?.data?.map((item, index) => (
              <SwiperSlide key={index}>
                <ProductCards
                  id={item.id}
                  image={IMG_URL + item?.image}
                  title={item?.name}
                  price={item?.quantity.find(() => true)?.price}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default RelatedProducts;
