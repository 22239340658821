import React from "react";
import AboutBanner from "./AboutBanner/AboutBanner";
import QualityProducts from "./QualityProducts/QualityProducts";
import OurMission from "./OurMission/OurMission";
import Dignitaries from "./Dignitaries/Dignitaries";

const About = () => {
  return (
    <>
      <AboutBanner />
      <QualityProducts />
      <OurMission />
      <Dignitaries />
    </>
  );
};

export default About;
