import React, { useContext, useEffect, useState } from "react";
import ProductDetailsPage from "./ProductDetailsPage/ProductDetailsPage";
import ProductContent from "./ProductContent/ProductContent";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import { useParams } from "react-router-dom";
import {
  AllProducts,
  getProductDetail,
} from "../../../utils/apis/Product/Product";
import { Context } from "../../../utils/context";

const ProductDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const { IMG_URL, htmlToReactParser } = useContext(Context);

  const getData = async () => {
    {
      const res = await getProductDetail(id);
      setData(res.data);
      GetProductsFilter(res.data.category_id);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const [related_products, setRelatedProducts] = useState([]);

  const GetProductsFilter = async (category_id = "") => {
    const res = await AllProducts(category_id);
    setRelatedProducts(res.data);
  };

  return (
    <>
      <ProductDetailsPage
        data={data}
        IMG_URL={IMG_URL}
        htmlToReactParser={htmlToReactParser}
      />
      <ProductContent
        data={data}
        IMG_URL={IMG_URL}
        htmlToReactParser={htmlToReactParser}
      />
      <RelatedProducts IMG_URL={IMG_URL} related_products={related_products} />
    </>
  );
};

export default ProductDetail;
