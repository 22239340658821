import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Category = async () => {
  try {
    return await getData(`/without-login/allcategories`);
  } catch (error) {
    console.error(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/without-login/allsubcategories/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const singleSubCategory = async (id) => {
  try {
    return await getData(`/without-login/subcategory/${id}`);
  } catch (error) {
    console.error(error);
  }
};
export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/without-login/allchildsubcategories/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const popularCategory = async () => {
  try {
    return await getData(`/without-login/all-pupular-category`);
  } catch (error) {
    console.error(error);
  }
};

export const OrdersCounte = async () => {
  try {
    return await getData(`/without-login/count-all-orders`);
  } catch (error) {
    console.error(error);
  }
};

export const AllProducts = async (
  category_id = "",
  sub_category_id = "",
  child_sub_category_id = "",
  end_price,
  page = 1,
  term = ""
) => {
  try {
    let url = "/without-login/product/filter";

    if (category_id) {
      url += `?category_id=${category_id}&`;
    }

    if (sub_category_id) {
      url += `?sub_category_id=${sub_category_id}&`;
    }

    if (child_sub_category_id) {
      url += `?child_sub_category_id=${child_sub_category_id}&`;
    }

    if (end_price) {
      url += `?end_price=${end_price}&`;
    }

    if (term) {
      url += `?term=${term}&`;
    }
    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const getProductDetail = async (id) => {
  try {
    return await getData(`/without-login/product/${id}`);
  } catch (error) {
    console.error(error);
  }
};

// export const getProductVariant = async (id) => {
//   try {
//     return await getData(`/without-login/product/variants/${id}`);
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getVariant = async (id) => {
  try {
    return await getData(`/without-login/product/variant/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const allProductReviewGet = async (id) => {
  try {
    return await getData(`/without-login/home/show/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const productReviewGet = async (id) => {
  try {
    return await getData(`/review/show/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const productReviewPost = async (data) => {
  try {
    return await postData(`/review/add`, data);
  } catch (error) {
    console.error(error);
  }
};

export const productList = async () => {
  try {
    return await getData(`/without-login/product`);
  } catch (error) {
    console.error(error);
  }
};
