import React, { useState } from "react";
import "./Product_Filter.css";
import { Form } from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
function Product_Filter(props) {
  const {
    category,
    max_price,
    GetProductsFilter,
    selectedCategory,
    setSelectedCategory,
  } = props;
  const [value, setValue] = useState(10);
  const minValue = 0;
  //   const maxValue = 50000;

  const [isOpen, setIsOpen] = useState(false);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  //   ----------------------------------------------------------------

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const value = Number(event.target.value);

    setSelectedCategory((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };
  //   console.log(selectedCategory);

  return (
    <>
      <div className="Product_Filter desktop-view">
        <div className="heading-holder">
          <h3 className="sub-text">Products by Category</h3>
        </div>

        <div className="checkbox-holder">
          <Form className="checkbox-holder-section">
            {category?.map((val, index) => (
              <>
                {" "}
                {/* <Form.Check
                type="checkbox"
                name="group1"
                value={selectedCategory}
                label={`${val?.name} (${val?.product_count})`}
                key={index}
              /> */}
                <Form.Check
                  type="checkbox"
                  name="group1"
                  value={val.id}
                  label={`${val?.name} (${val?.product_count})`}
                  key={index}
                  onChange={handleCheckboxChange}
                  checked={selectedCategory.includes(val.id)}
                />
              </>
            ))}

            {/* <Form.Check
              type="checkbox"
              name="group1"
              label="Carrier Oil (45)"
            />

            <Form.Check
              type="checkbox"
              name="group1"
              label="Phytochemicals (98)"
            /> */}
          </Form>
        </div>

        <hr className="hr-line-holder" />

        <div className="price-range-section">
          <div className="heading-holder">
            <h3 className="sub-text">Price Range</h3>
          </div>
          <div className="range-bar">
            <div className="custom-slider-container">
              <InputRange
                draggableTrack
                minValue={minValue}
                maxValue={max_price}
                formatLabel={(value) => `₹${value}`}
                value={value}
                onChange={(value) => setValue(value)}
                onChangeComplete={(value) => {
                  GetProductsFilter("", value);
                  // console.log(value);
                }}
              />
              <div className="slider-value">
                <div className="slide">
                  <span className="text">₹{minValue}</span>
                  <span className="text">₹{max_price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Mobile-View">
        <div className="product-filter-container">
          {/* Filter icon to toggle visibility */}
          <div className="filter-icon" onClick={toggleFilter}>
            <FontAwesomeIcon icon={faFilter} />
          </div>

          {/* Conditional rendering of filter section based on isOpen state */}
          {isOpen && (
            <div className="Product_Filter">
              <div className="heading-holder">
                <h3 className="sub-text">Products by Category</h3>
              </div>

              <div className="checkbox-holder">
                <Form className="checkbox-holder-section">
                  {category?.map((val, index) => (
                    <>
                      <Form.Check
                        type="checkbox"
                        name="group1"
                        value={val.id}
                        label={`${val?.name} (${val?.product_count})`}
                        key={index}
                        onChange={handleCheckboxChange}
                        checked={selectedCategory.includes(val.id)}
                      />
                    </>
                  ))}
                  {/* <Form.Check
                    type="checkbox"
                    name="group1"
                    label="Essential Oil (78)"
                  />

                  <Form.Check
                    type="checkbox"
                    name="group1"
                    label="Carrier Oil (45)"
                  />

                  <Form.Check
                    type="checkbox"
                    name="group1"
                    label="Phytochemicals (98)"
                  /> */}
                </Form>
              </div>

              <hr className="hr-line-holder" />

              <div className="price-range-section">
                <div className="heading-holder">
                  <h3 className="sub-text">Price Range</h3>
                </div>
                <div className="range-bar">
                  <div className="custom-slider-container">
                    <InputRange
                      draggableTrack
                      minValue={minValue} // Setting the minimum value
                      maxValue={max_price} // Setting the maximum value
                      formatLabel={(value) => `₹${value}`} // Formatting label with rupee symbol
                      value={value}
                      onChange={(value) => setValue(value)}
                      onChangeComplete={(value) => {
                        GetProductsFilter("", value);
                        // console.log(value);
                      }}
                    />
                    <div className="slider-value">
                      <div className="slide">
                        <span className="text">₹{minValue}</span>
                        <span className="text">₹{max_price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Product_Filter;
