import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import Return_Reason_Modal from "../CommonComponents/Common_Modal/Return_Reason_Modal/Return_Reason_Modal";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal";
import { useNavigate } from "react-router-dom";
import {
  OrderCancel,
  singleOrderGet,
} from "../../utils/apis/myAccount/myAccount";
import { Context } from "../../utils/context";
import Cancel_Reason_Modal from "../CommonComponents/Common_Modal/Cancel_Reason_Modal/Cancel_Reason_Modal";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
import { useForm } from "react-hook-form";

const CancleOrder = (orderId, onHide) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "ACCOUNT", link: "/account" },
    { text: "YOUR ORDERS", link: "/your-order", className: "active" },
  ]);

  const orders = [
    {
      image: process.env.PUBLIC_URL + "/assests/Orders/balsam-tolu.png",
      name: "Balsam Tolu (Reconstitution)",
      address:
        "Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016",
      method: "Online",
      quantity: "1 kg",
      pack: "1",
    },
  ];

  const [modalShow, setModalShow] = useState(false);
  const [modalCanleReasonShow, setModalCanleReasonShow] = useState(false);

  const [reason, setReason] = useState("");
  const [remark, setRemark] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [isOpen]);

  const navigate = useNavigate();
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const [modalSuccessfullShow1, setSuccessfullModalShow1] = useState(false);
  useEffect(() => {
    if (modalSuccessfullShow) {
      const timer = setTimeout(() => {
        setSuccessfullModalShow(false);
        navigate("/your-order");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [modalSuccessfullShow, navigate]);

  useEffect(() => {
    if (modalSuccessfullShow1) {
      const timer = setTimeout(() => {
        setSuccessfullModalShow1(false);
        navigate("/your-order");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [modalSuccessfullShow1, navigate]);

  //   ------------------------------------------------------------------------------------------------
  //   singleOrderGet

  const { id } = useParams();
  const { IMG_URL } = useContext(Context);
  const [data, setdata] = useState({});
  const getData = async () => {
    const res = await singleOrderGet(id);
    setdata(res?.data);
  };

  useEffect(() => {
    if (id) {
      setValue("order_id", id);
      getData();
    }
  }, [id]);

  //

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);

    const res = await OrderCancel(data);
    if (res?.success) {
      setSuccessfullModalShow1(true);
    } else {
      // setErrorMessage(res?.message?.errors?.reason?.msg);
    }
  };

  return (
    <>
      <section className="ReturnOrder Your-Order">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="mid-title">Your Orders</h1>
          <div className="Orders-Main">
            {/* {orders.map((item, index) => ( */}
            <div className="ordermain_divv">
              <div className="order_main">
                <Row>
                  <Col
                    xxl={3}
                    xl={3}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mb-2"
                  >
                    <div className="image">
                      <div className="orderlist_imgdiv">
                        <img
                          src={IMG_URL + data?.product?.image}
                          className="orderlist_img"
                          alt="Product"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xxl={9}
                    xl={9}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={12}
                    className="my-auto mb-2"
                  >
                    <div className="order_data">
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Product Name:</p>
                          </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">
                              {" "}
                              {data?.product?.name} ({" "}
                              {data?.product?.short_description})
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Address:</p>
                          </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">
                              {" "}
                              {`${data?.address?.add_type} ${data?.address?.address} ${data?.address?.landmark} - ${data?.address?.zip_code}`}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Payment Method:</p>
                          </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={8} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text"> {data?.payment_mode}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Quantity:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">
                              {" "}
                              {data?.product_quantity?.quantity}{" "}
                              {data?.product_quantity?.unit?.name}
                            </p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Qty. Of Pack:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">{data?.quantity}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">Total amount:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">₹ {data?.total}</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">Status:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">
                              {" "}
                              {data?.order_payment?.status ? "Paid" : "Unpaid"}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {/* ))} */}
          </div>
          <div className="Reason">
            <div className="select" onClick={openModal}>
              <p className="mini-text">Select a reason for cancellation</p>
              <FontAwesomeIcon icon={faAngleRight} />
              <Cancel_Reason_Modal
                isOpen={isOpen}
                setReason={setReason}
                reason={reason}
                onClose={closeModal}
                getValues={getValues}
                register={register}
                setValue={setValue}
              />
            </div>
          </div>
          <div className="Cancellation  Cancellation-reason">
            <p>
              Could you tell us a reason for cancellation?{" "}
              <span>(optional)</span>
            </p>
            <Form>
              <Form.Control
                as="textarea"
                role="1"
                {...register("reason", {})}
                placeholder="Type a reason"
              />
            </Form>
          </div>
          {errors?.order_cancel_reason_id && (
            <sup className="text-danger">
              {errors?.order_cancel_reason_id?.message}
            </sup>
          )}
          <div className="Button-Main">
            <Button_Common
              className="cancel text me-4"
              text="CANCEL"
              onClick={() => {
                setSuccessfullModalShow(true);
              }}
            />

            {/* <Link to="/cancel-order"> */}
            <Button_Common
              className="return text"
              text="SUBMIT"
              onClick={handleSubmit(onSubmit)}
              // onClick={handleSubmit}
              // onClick={() => {
              //   setSuccessfullModalShow1(true);
              // }}
            />
            {/* </Link> */}
          </div>
        </Container>
      </section>
      <Successfull_Modal
        show={modalSuccessfullShow}
        onHide={() => {
          setSuccessfullModalShow(false);
        }}
        message={"Order Cancel Successfully!"}
      />
      <Successfull_Modal
        show={modalSuccessfullShow1}
        onHide={() => {
          setSuccessfullModalShow1(false);
        }}
        message={"Order Cancle Successfully!"}
      />
    </>
  );
};

export default CancleOrder;
