import { Rating } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Banner_Heading_Text from "../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
// import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import "./YourOrders.css";
import { Link } from "react-router-dom";
import Rate_Us_Modal from "../CommonComponents/Common_Modal/Rate_Us_Modal/Rate_Us_Modal";
import { GetAllAddress } from "../../utils/apis/address";
import { orderGet } from "../../utils/apis/myAccount/myAccount";
import { Context } from "../../utils/context";
import { formatDate, Order_Status } from "../../utils/common";
import { OrderStatus } from "../../utils/apis/master/Master";
import Orders from "./Orders/Orders";
import Return from "./Return/Return";
import Cancel from "./Cancel/Cancel";
const YourOrders = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "ACCOUNT", link: "/account" },
    { text: "YOUR ORDERS", link: "", className: "active" },
  ]);
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);

  const orders = [
    {
      image: process.env.PUBLIC_URL + "/assests/Orders/balsam-tolu.png",
      name: "Balsam Tolu (Reconstitution)",
      address:
        "Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016",
      method: "Online",
      quantity: "1 kg",
      pack: "1",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Orders/balsam-tolu.png",
      name: "Balsam Tolu (Reconstitution)",
      address:
        "Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016",
      method: "Online",
      quantity: "1 kg",
      pack: "1",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Orders/balsam-tolu.png",
      name: "Balsam Tolu (Reconstitution)",
      address:
        "Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016",
      method: "Online",
      quantity: "1 kg",
      pack: "1",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Orders/balsam-tolu.png",
      name: "Balsam Tolu (Reconstitution)",
      address:
        "Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016",
      method: "Online",
      quantity: "1 kg",
      pack: "1",
    },
  ];

  const handleSubmit = (id) => {
    setSuccessfullModalShow(id);
    // props.onHide();
  };

  //   useEffect(() => {
  //     let timer;
  //     if (modalSuccessfullShow) {
  //       timer = setTimeout(() => {
  //         setSuccessfullModalShow(false);
  //       }, 2000);
  //     }
  //     return () => clearTimeout(timer);
  //   }, [modalSuccessfullShow]);

  //   ----------------------------------------------------------------
  //   orderGet
  const { IMG_URL } = useContext(Context);
  const [allOrders, setAllOrders] = useState([]);
  const [orderStatus, setorderStatus] = useState([]);
  const getData = async () => {
    const res = await orderGet();
    setAllOrders(res.data);
    {
      const res = await OrderStatus();
      setorderStatus(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="Your-Order">
        <Container>
          <Banner_Heading_Text
            headingText={""}
            breadcrumbItems={breadcrumbItems}
          />
          <h1 className="mid-title">Your Orders</h1>

          <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Orders">
              <Row>
                <div className="col-md-12">
                  <Nav variant="pills" className=" tabs-holder flex">
                    <Nav.Item>
                      <Nav.Link eventKey="Orders">Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Return_Orders">
                        Return Orders
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Cancel_Orders">
                        Cancel Orders
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-12">
                  <Tab.Content>
                    <Tab.Pane eventKey="Orders">
                      <Orders />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Return_Orders">
                      <Return />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Cancel_Orders">
                      <Cancel />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
    </>
  );
};

export default YourOrders;
