import React, { useContext, useEffect, useState } from "react";
import "./AboutBanner.css";
import Breadcrumbs from "../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { Col, Container, Row } from "react-bootstrap";
import Common_Banner from "../../CommonComponents/Common_Banner/Common_Banner";
import { Context } from "../../../utils/context";
import { getWhoWhereAre } from "../../../utils/apis/home";
const AboutBanner = () => {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "ABOUT US", link: "/about", className: "active" },
  ]);
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState({});

  const getData = async () => {
    const res = await getWhoWhereAre();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="AboutBanner">
        <div className="banner-about">
          <Common_Banner
            bannerImg={
              process.env.PUBLIC_URL + "/assests/AboutUs/Banner/aboutus.png"
            }
            headingText={"About Us"}
            breadcrumbItems={breadcrumbItems}
          />
          <Container>
            <div className="Who-We-Are">
              <Row>
                <Col xxl={6} xl={6} lg={6}>
                  <div className="Image-content">
                    <div className="Main-Img">
                      <img src={IMG_URL + data?.logo_one} className="Who" />
                      <img src={IMG_URL + data?.logo_two} className="Who2" />
                    </div>
                    <div className="content-title">
                      <h1 className="title">{data?.name}</h1>
                    </div>
                  </div>
                </Col>
                <Col xxl={6} xl={6} lg={6}>
                  <div className="Content-Who">
                    <div className="border-who"></div>
                    <p className="mini-text-p">
                      {htmlToReactParser.parse(data?.description) || ""}
                    </p>
                  </div>
                  {/* <div className="who-content">
                    <p className="mini-text-p mt-2">
                      Our esteemed customers have consistently voiced their
                      trust in us, confidently purchasing our Essential Oils
                      without hesitation. Their satisfaction speaks volumes, as
                      they rely on our reliability and commitment to excellence,
                      consistently finding joy and contentment in their
                      purchases.
                    </p>
                  </div> */}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
