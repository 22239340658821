import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Or your preferred router
import "./Breadcrumbs.css"
const CustomBreadcrumb = ({ items, className }) => (
  <section className='Bread-main'>
   <Breadcrumb>
        {items?.map((item, index) => (
            <Breadcrumb.Item  className={className} key={index} linkAs={Link} linkProps={{ to: item?.link }}>
                {item?.text}
            </Breadcrumb.Item>
        ))}
        <style>
          {`
            .breadcrumb-item + .breadcrumb-item::before {
                content: ' > ';
              style={item.style}
            }
          `}
        </style>
    </Breadcrumb>
  </section>
 
);

export default CustomBreadcrumb;
