import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import OTP_Modal from "../CommonComponents/Common_Modal/OTP_Modal/OTP_Modal";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Login_Activity.css";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import { GetProfile, ProfileEdit } from "../../utils/apis/login/Login";
function Login_Activity() {
  const [showOtpModal, setOtpModal] = useState(false);
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [showEditButton, setShowEditButton] = useState(true);

  const navigate = useNavigate();

  // Function to handle OTP submission
  const handleOtpSubmit = () => {
    setOtpModal(true);

    // Simulate OTP verification delay
    setTimeout(() => {
      setOtpModal(false);
    }, 1000); // Adjust timeout as needed
  };

  // Function to handle Edit button click
  const handleEditClick = () => {
    setEditMode(true);
    setShowEditButton(false);
  };

  // Function to handle Back button click
  const handleBackClick = () => {
    clearErrors();
    setOtpModal(false);
    setEditMode(false);
    setShowEditButton(true);
  };

  // Handle modal close and navigation
  useEffect(() => {
    if (modalSuccessfullShow) {
      const timer = setTimeout(() => {
        setSuccessfullModalShow(false);
        navigate("/account"); // Navigate to the account page
      }, 3000); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Clean up the timer on component unmount
    }
  }, [modalSuccessfullShow, navigate]);

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "ACCOUNT", link: "/account" },
    { text: "LOGIN ACTIVITY", link: "/" },
  ]);

  // --------------------------------------------------------------

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);
    const res = await ProfileEdit(data);

    if (res?.success) {
      // console.log(res);
      clearErrors();
      setSuccessfullModalShow(true);
      setEditMode(false);
    } else {
      if (res?.message?.contact_no) {
        setError("contact_no", {
          message: res?.message?.contact_no,
        });
      }

      if (res?.message?.email) {
        setError("email", {
          message: res?.message?.email,
        });
      }
    }
  };

  const getData = async () => {
    const res = await GetProfile();
    reset(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="Login">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <h1 className="mid-title">Login Activity</h1>
          <div className="Login_Activity Modal-holder">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-xs-12 col-12">
                <div className="Login_Activity_Form_Holder">
                  <div className="LogIn">
                    <div className="row bg-color me-0 ms-0">
                      <div className="col-md-12 p-0">
                        <div className="row justify-content-center me-0 ms-0">
                          <div className="col-md-12">
                            <div className="log_in_form">
                              <div className="edit_span_text_holder">
                                {showEditButton && !editMode && (
                                  <span
                                    className="edit_span_text"
                                    onClick={handleEditClick}
                                  >
                                    Edit
                                  </span>
                                )}
                              </div>
                              <Form>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Control
                                    type="text"
                                    disabled={!editMode}
                                    placeholder="First Name"
                                    {...register("first_name", {
                                      required: "First name required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.first_name,
                                      "is-valid": getValues("first_name"),
                                    })}
                                    onKeyDown={(event) => {
                                      if (
                                        !RegxExpression.name.test(event.key)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="">
                                  <Form.Control
                                    type="text"
                                    disabled={!editMode}
                                    placeholder="Last Name"
                                    {...register("last_name", {
                                      required: "Last name required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.last_name,
                                      "is-valid": getValues("last_name"),
                                    })}
                                    onKeyDown={(event) => {
                                      if (
                                        !RegxExpression.name.test(event.key)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="">
                                  <Form.Control
                                    type="email"
                                    disabled={!editMode}
                                    placeholder="Email"
                                    {...register("email", {
                                      required: "Email is required",
                                      pattern: {
                                        value: RegxExpression.email,
                                        message: "Invalid email address",
                                      },
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.email,
                                      "is-valid": getValues("email"),
                                    })}
                                  />
                                  {errors?.email && (
                                    <sup className="text-danger">
                                      {errors?.email?.message}
                                    </sup>
                                  )}
                                </Form.Group>

                                <Form.Group
                                  className="password-field-holder mb-3"
                                  controlId=""
                                >
                                  <Form.Control
                                    type="text"
                                    disabled={!editMode}
                                    className={classNames("", {
                                      "is-invalid": errors?.contact_no,
                                      "is-valid": getValues("contact_no"),
                                    })}
                                    name="contact_no"
                                    placeholder="Enter Mobile Number"
                                    {...register("contact_no", {
                                      required: "Field is required",
                                      minLength: {
                                        value: 10,
                                        message:
                                          "Number should be at least 10 characters",
                                      },
                                    })}
                                    onKeyDown={(event) => {
                                      if (
                                        !RegxExpression.phone.test(event.key)
                                      ) {
                                        if (event.key !== "Backspace") {
                                          event.preventDefault();
                                        }
                                      }
                                    }}
                                    maxLength={10}
                                  />
                                  {errors?.contact_no && (
                                    <sup className="text-danger">
                                      {errors?.contact_no?.message}
                                    </sup>
                                  )}
                                  {/* {editMode && (
                                    <div className="eye-icon-holder">
                                      <span
                                        className="edit_span_text"
                                        onClick={handleOtpSubmit}
                                      >
                                        Verify
                                      </span>
                                    </div>
                                  )} */}
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="btn-holder yes_no_btn_holder">
                  {editMode && (
                    <>
                      {/* <Link to="/account"> */}
                      <Common_Button
                        className="SubmitBtn me-3 green text"
                        text="CANCEL"
                        onClick={handleBackClick}
                        // onClick={() => {
                        //   setEditMode(false);
                        //   setShowEditButton(true);

                        // }}
                      />
                      {/* </Link> */}
                      <Common_Button
                        className="YesBtn brown text"
                        text="SAVE CHANGES"
                        onClick={handleSubmit(onSubmit)}
                        // onClick={() => {
                        //   setSuccessfullModalShow(true);
                        //   setShowEditButton(false);
                        // }}
                      />
                    </>
                  )}
                  {/* {editMode &&  (
                    <p className="text" onClick={handleBackClick}>
                      <FontAwesomeIcon icon={faArrowLeft} className="icon" />{" "}
                      BACK
                    </p>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <OTP_Modal
        show={showOtpModal}
        onHide={() => {
          setOtpModal(false);
          setEditMode(false);
          setShowEditButton(true);
        }}
      />

      <Successfull_Modal
        show={modalSuccessfullShow}
        onHide={() => {
          setSuccessfullModalShow(false);
          setShowEditButton(true);
        }}
        message={"Login Changes are saved Successfully!"}
      />
    </>
  );
}

export default Login_Activity;
