import React from 'react';
import Lottie from 'react-lottie';
import './Success_New.css';
import * as animationData from './Success _ new.json';

const Success_New = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
  return (
    <>
      <section className='main'>
            <div className='Successfull_Lottie_icon'>
                <Lottie className="me-auto"
                options={defaultOptions}
                // height={60}
                // width={60}          
                />       
        </div>
      </section>
    </>
  )
}

export default Success_New
