import React, { useContext, useEffect, useState } from "react";
import "./WhoWeAre.css";
import { Col, Container, Row } from "react-bootstrap";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { getWhoWhereAre } from "../../../utils/apis/home";

const WhoWeAre = () => {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState({});

  const getData = async () => {
    const res = await getWhoWhereAre();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {/* WhoWeAre section started */}
      <section className="WhoWeAre">
        <Container>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12}>
              <div className="WhoWeAre-image">
                <div className="Image-One">
                  <div className="Imag1">
                    <img src={IMG_URL + data?.logo_one} className="who1" />
                  </div>
                </div>
                <div className="Image-Two">
                  <div className="image-2">
                    <img src={IMG_URL + data?.logo_two} className="who1" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12}>
              <div className="Content-Who-are">
                <h1 className="big-title">{data?.name}</h1>
                <div className="text-content">
                  <div className="border-who"></div>
                  <p className="sub-text">
                    {htmlToReactParser.parse(data?.description) || ""}
                  </p>
                </div>
                <Link to="/about">
                  <div className="Know">
                    <Button_Common
                      className="know-more mini-text"
                      icon={faArrowRight}
                      text="KNOW MORE ABOUT US"
                    ></Button_Common>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* WhoWeAre section End */}
    </>
  );
};

export default WhoWeAre;
