import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Contact.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Banner_Heading_Text from "../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  faEnvelope,
  faLocationDot,
  faMapLocationDot,
  faPhone,
  faCircleXmark,
  faExpand,
  faMaximize,
  faMinimize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Success_New from "../Animation/Success_New/Success_New"; // Ensure you have this component
import { AppSetup, postContactUs } from "../../utils/apis/common/Common";
import { Context } from "../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
function Contact() {
  const [phoneValue, setPhoneValue] = useState("");
  const [showMap, setShowMap] = useState(false);
  const handle = useFullScreenHandle();

  const [isMessageSent, setIsMessageSent] = useState(false); // State for showing the success message
  const navigate = useNavigate(); // Use navigate for redirection

  const toggleMap = () => {
    if (showMap && handle.active) {
      handle.exit();
    }
    setShowMap(!showMap);
  };

  const expandMap = () => {
    if (handle.active) {
      handle.exit();
    } else {
      handle.enter();
    }
  };

  const handleSendMessage = () => {
    setIsMessageSent(true);

    setTimeout(() => {
      setIsMessageSent(false);
      navigate("/");
    }, 1000);
  };

  const [breadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "CONTACT US", link: "/contact", className: "active" },
  ]);
  // ----------------------------------------------------------------
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await AppSetup();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  //   ----------------------------------------------------------------

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await postContactUs(data);
    if (res?.success) {
      handleSendMessage();
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  return (
    <>
      <section className="cont_sectionn">
        <div className="contact_section">
          <Container>
            <div className="contact_main">
              <Banner_Heading_Text
                headingText={""}
                breadcrumbItems={breadcrumbItems}
              />
              <h2 className="title">Contact Us</h2>
              <div>
                <Row>
                  <Col xxl={9} xl={9} lg={10} md={11} sm={12}>
                    <div
                      className={`Information-client ${
                        showMap ? "hidden" : ""
                      }`}
                    >
                      <Row>
                        <Col
                          xxl={8}
                          xl={8}
                          lg={7}
                          md={12}
                          xs={12}
                          sm={12}
                          className="pe-xl-0 pe-xxl-0 pe-lg-0 pe-md-0"
                        >
                          <div className="leftdivv">
                            {!isMessageSent ? (
                              <>
                                <Form>
                                  <Form.Group
                                    className="mb-4"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Your Name"
                                      {...register("name", {
                                        required: "Name is required",
                                      })}
                                      className={classNames("", {
                                        "is-invalid": errors?.name,
                                      })}
                                      onKeyDown={(event) => {
                                        if (!/[A-Z-a-z ]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />{" "}
                                    {errors.name && (
                                      <div className="invalid-feedback">
                                        {errors.name.message}
                                      </div>
                                    )}
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-4"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      placeholder="E-mail Address"
                                      {...register("email", {
                                        required: "Email Id required",
                                        pattern: {
                                          value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/,
                                          message: "Email Id Invalid",
                                        },
                                      })}
                                      className={classNames("", {
                                        "is-invalid": errors?.email,
                                      })}
                                    />
                                    {errors.email && (
                                      <div className="invalid-feedback">
                                        {errors.email.message}
                                      </div>
                                    )}
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-4"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Control
                                      type="text"
                                      name="subject"
                                      placeholder="Subject"
                                      {...register("subject", {
                                        required: "subject is required",
                                      })}
                                      className={classNames("", {
                                        "is-invalid": errors?.subject,
                                      })}
                                    />{" "}
                                    {errors.subject && (
                                      <div className="invalid-feedback">
                                        {errors.subject.message}
                                      </div>
                                    )}
                                  </Form.Group>
                                  <Controller
                                    name="contact_no"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Mobile number is required",
                                    }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <PhoneInput
                                        country={"in"}
                                        value={value}
                                        placeholder="Enter Mobile Number"
                                        onChange={onChange}
                                        className={classNames("mb-4", {
                                          "is-invalid": errors?.contact_no,
                                        })}
                                        inputProps={{
                                          name: "contact_no",
                                          required: true,
                                          autoFocus: true,
                                        }}
                                      />
                                    )}
                                  />
                                  {errors.contact_no && (
                                    <div className="invalid-feedback d-block">
                                      {errors.contact_no.message}
                                    </div>
                                  )}
                                  <Form.Group
                                    className="mb-4"
                                    controlId="exampleForm.ControlTextarea1"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      {...register("message", {
                                        required: "Message required",
                                      })}
                                      className={classNames("", {
                                        "is-invalid": errors?.message,
                                      })}
                                      rows={1}
                                      placeholder="Your Message"
                                    />{" "}
                                    {errors.message && (
                                      <div className="invalid-feedback">
                                        {errors.message.message}
                                      </div>
                                    )}
                                  </Form.Group>
                                </Form>
                                <Form className="check">
                                  <div className="mb-3">
                                    <Form.Check
                                      type="checkbox"
                                      {...register("privacy_policy", {
                                        required: "Privacy Policy required",
                                      })}
                                      className={classNames("", {
                                        "is-invalid": errors?.privacy_policy,
                                      })}
                                      id="default-checkbox"
                                      label="I have read and agree to the Privacy Policy."
                                    />
                                    {errors.privacy_policy && (
                                      <div className="invalid-feedback d-block">
                                        {errors.privacy_policy.message}
                                      </div>
                                    )}
                                  </div>
                                </Form>
                                <div className="btndiv">
                                  <button
                                    className="btnnnn"
                                    // onClick={handleSendMessage}
                                    onClick={handleSubmit(onSubmit)}
                                  >
                                    Send Message
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="sent-successfull">
                                <div className="success">
                                  <h1 className="price-text">
                                    Message Sent Successfully!
                                  </h1>
                                </div>
                                <div className="success-lottie">
                                  <Success_New />
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col
                          xxl={4}
                          xl={4}
                          lg={5}
                          md={12}
                          sm={12}
                          xs={12}
                          className="ps-xxl-0 ps-xl-0 ps-lg-0 ps-md-0"
                        >
                          <div className="leftdivv1">
                            <div className="d-flex align-items-center information mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon
                                    icon={faPhone}
                                    className="icon"
                                  />
                                </div>
                              </div>
                              <p className="text mb-0 ms-2">
                                {data?.contact_no}
                              </p>
                            </div>
                            <div className="d-flex align-items-center information mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="icon"
                                  />
                                </div>
                              </div>
                              <p className="text mb-0 ms-2">{data?.email}</p>
                            </div>
                            <div className="d-flex align-items-center information mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    className="icon"
                                  />
                                </div>
                              </div>
                              <p className="text mb-0 ms-2">{data?.address}</p>
                            </div>
                            <div className="d-flex align-items-center information mt-3">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon
                                    icon={faMapLocationDot}
                                    className="icon"
                                  />
                                </div>
                              </div>
                              <p
                                className="text mb-0 ms-2 text-decoration-underline cursor-pointer"
                                onClick={toggleMap}
                              >
                                Find Us on map
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <FullScreen handle={handle}>
                      <div
                        className={`map ${!showMap ? "hidden" : "show"} ${
                          handle.active ? "fullscreen" : ""
                        }`}
                      >
                        <iframe
                          className="gmap_iframe"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15133.39075304903!2d73.7810866!3d18.5131838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1719574302642!5m2!1sen!2sin"
                        ></iframe>
                        <div className="close">
                          <div className="map-icons">
                            <FontAwesomeIcon
                              icon={handle.active ? faMinimize : faMaximize}
                              className="expand"
                              onClick={expandMap}
                            />
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              className="close-icon"
                              onClick={toggleMap}
                            />
                          </div>
                        </div>
                      </div>
                    </FullScreen>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Contact;
