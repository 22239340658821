import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";

import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import "./Heart_Component.css";
import {
  addWishlist,
  wishlistContext,
} from "../../../utils/apis/Wishlist/Wishlist";
import { Context } from "../../../utils/context";
import Warning_Modal from "../Common_Modal/Warning_Modal/Warning_Modal";

function Heart_Component({ status, product_id }) {
  const { signin, setWishlist } = useContext(Context);
  const [isFavorite, setIsFavorite] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    setIsFavorite(status);
  }, [status]);
  const toggleFavorite = async () => {
    if (signin) {
      const data = {
        product_id,
      };
      await addWishlist(data);
      await wishlistContext(signin, setWishlist);
      await setIsFavorite(!isFavorite);
    } else {
      setModalShow(true);
    }
  };

  return (
    <>
      <div className="heart-component">
        <div onClick={toggleFavorite} className="favorite-button">
          <FontAwesomeIcon
            icon={isFavorite ? solidHeart : regularHeart}
            className={isFavorite ? "red-heart" : "black-heart"}
            // onClick={() => setModalShow(true)}
          />
        </div>
      </div>

      <Warning_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"Please Login Before Add To Wish List"}
      />
    </>
  );
}

export default Heart_Component;
