import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import Common_Button from "../../Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { useNavigate } from "react-router-dom";
import "./Permision_Delete_Modal.css"
function Permision_Delete_Modal(props) {
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (modalSuccessfullShow) {
            timer = setTimeout(() => {
                setSuccessfullModalShow(false);
                // Uncomment the navigate if you want to redirect
                // navigate("/product-detail");
            }, 1000); // Timer duration in milliseconds (1 second)
        }

        return () => clearTimeout(timer);
    }, [modalSuccessfullShow]);

    return (
        <>
            <Modal
                {...props}
                size="sm"
                className="Modal-holder Permission"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="LogIn">
                        <div className="row bg-color me-0 ms-0">
                            <div className="col-md-12 p-0">
                                <div className="row justify-content-center me-0 ms-0">
                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                        <div className="log_in_form">
                                            <div className="heading-holder">
                                                <p className="text-center mb-0">Do You Want To</p>
                                                <p className="bold-text-holder text-center">
                                                    <b>Delete the Item?</b>
                                                </p>
                                            </div>

                                            <div className="btn-holder yes_no_btn_holder my-0 py-0">
                                                <Common_Button
                                                    className="YesBtn brown text me-3"
                                                    text="Yes"
                                                    onClick={() => {
                                                        props.onHide();
                                                        setSuccessfullModalShow(true);
                                                    }}
                                                  
                                                />
                                                <Common_Button
                                                    className="SubmitBtn green  text"
                                                    text="No"
                                                    onClick={props.onHide} 
                                                  
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Bottom_Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => setSuccessfullModalShow(false)}
                text="Item Deleted Successfully!"
            />
        </>
    );
}

export default Permision_Delete_Modal;
