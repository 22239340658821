import React, { useContext, useEffect, useState } from "react";
import "./FaqTabs.css";
import Contact from "../../Contact/Contact";
import { Accordion, Container, Nav, Tab } from "react-bootstrap";
import { getFAQCategory } from "../../../utils/apis/faqs";
import { Context } from "../../../utils/context";

function FaqTabs() {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);
  const [subdata, setSubData] = useState([]);

  const getData = async () => {
    const res = await getFAQCategory();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="FAq-Tab-Section">
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="0">
            <div className="faq_main_div">
              <div className="faqconts">
                <Tab.Content>
                  {data?.map((val, index) => (
                    <Tab.Pane eventKey={`${index}`} key={index}>
                      <div className="accord_section">
                        <Accordion defaultActiveKey="00">
                          {val?.faq?.map((val2, index2) => (
                            <Accordion.Item
                              eventKey={`${index}${index2}`}
                              key={index2}
                            >
                              <Accordion.Header className="accordheader">
                                {val2?.name}
                              </Accordion.Header>
                              <Accordion.Body>
                                {htmlToReactParser.parse(val2?.description) ||
                                  ""}

                                {/* <p className="faq_text">
                                  Most companies offer a range of standard
                                  shipping methods, which typically include:
                                </p>
                                <ul>
                                  <li className="faq_text">
                                    <span className="faq_heading">
                                      1. Standard Ground Shipping:
                                    </span>
                                    This is usually the most economical option
                                    and typically takes between 3 to 7 business
                                    days.
                                  </li>
                                  <li className="faq_text">
                                    <span className="faq_heading">
                                      2. Express Shipping:
                                    </span>
                                    Faster than standard shipping, often
                                    delivering within 2 to 3 business days.
                                  </li>
                                  <li className="faq_text">
                                    <span className="faq_heading">
                                      3. Overnight Shipping:
                                    </span>
                                    The fastest option, delivering the next
                                    business day.
                                  </li>
                                </ul> */}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </Tab.Pane>
                  ))}

                  {/* <Tab.Pane eventKey="second">
                    <div className="accord_section">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="accordheader">
                            What Are The Shipping Charges?
                          </Accordion.Header>
                          
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="accordheader">
                            What Are The Shipping Charges?
                          </Accordion.Header>
                   
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header className="accordheader">
                            Can I Cancel My Order?
                          </Accordion.Header>
                     
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header className="accordheader">
                            Is Cash On Delivery Available?
                          </Accordion.Header>
                         
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="third">
                    <div className="accord_section">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="accordheader">
                            What Shipping Methods Are Available?
                          </Accordion.Header>
                          
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="accordheader">
                            How Do I Track My Order?
                          </Accordion.Header>
                         
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header className="accordheader">
                            Do You Allow To Exchange?{" "}
                          </Accordion.Header>
                     
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header className="accordheader">
                            How Will I Know If Order Is Placed Successfully?
                          </Accordion.Header>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Tab.Pane> */}
                </Tab.Content>
              </div>

              <div className="tab_section">
                <Nav variant="pills" className="">
                  {data?.map((val, index) => (
                    <Nav.Item className="tabitemss me-3 mb-2">
                      <Nav.Link eventKey={`${index}`}>{val?.name}</Nav.Link>
                    </Nav.Item>
                  ))}

                  {/* <Nav.Item className="tabitemss me-3 mb-2">
                    <Nav.Link eventKey="second">PAYMENT FAQs</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="tabitemss mb-2">
                    <Nav.Link eventKey="third">ONLINE SELLER FAQs</Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
            </div>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
}

export default FaqTabs;
