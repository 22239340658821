import { Rating } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common";
import { Link } from "react-router-dom";
import Rate_Us_Modal from "../../CommonComponents/Common_Modal/Rate_Us_Modal/Rate_Us_Modal";
import {
  CanceledOrderGet,
  orderGet,
} from "../../../utils/apis/myAccount/myAccount";
import { Context } from "../../../utils/context";
import { formatDate, Order_Status } from "../../../utils/common";
import { OrderStatus } from "../../../utils/apis/master/Master";
const Cancel = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "ACCOUNT", link: "/account" },
    { text: "YOUR ORDERS", link: "", className: "active" },
  ]);
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);

  const handleSubmit = (id) => {
    setSuccessfullModalShow(id);
    // props.onHide();
  };

  //   useEffect(() => {
  //     let timer;
  //     if (modalSuccessfullShow) {
  //       timer = setTimeout(() => {
  //         setSuccessfullModalShow(false);
  //       }, 2000);
  //     }
  //     return () => clearTimeout(timer);
  //   }, [modalSuccessfullShow]);

  //   ----------------------------------------------------------------
  //   orderGet
  const { IMG_URL } = useContext(Context);
  const [allOrders, setAllOrders] = useState([]);
  const [orderStatus, setorderStatus] = useState([]);
  const getData = async () => {
    const res = await CanceledOrderGet();
    setAllOrders(res.data);
    {
      const res = await OrderStatus();
      setorderStatus(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="Your-Order">
        <Container>
          <div className="Orders-Main">
            {allOrders?.map((order, index) => (
              <React.Fragment key={index}>
                <div className="ordermain_divv">
                  <div className="oreder_main">
                    <Row>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={12}
                        sm={12}
                        xs={12}
                        className="mb-2"
                      >
                        <div className="image">
                          <div className="orderlist_imgdiv">
                            <img
                              src={IMG_URL + order?.product?.image}
                              className="orderlist_img"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col
                        xxl={9}
                        xl={9}
                        lg={8}
                        md={12}
                        sm={12}
                        className="my-auto mb-2"
                      >
                        <div className="order_data">
                          <Row>
                            <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">Product Name:</p>
                              </div>
                            </Col>
                            <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">
                                  {order?.product?.name} ({" "}
                                  {order?.product?.short_description})
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">Address:</p>
                              </div>
                            </Col>
                            <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">
                                  {`${order?.address?.add_type} ${order?.address?.address} ${order?.address?.landmark} - ${order?.address?.zip_code}`}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">Payment Method:</p>
                              </div>
                            </Col>
                            <Col xxl={9} xl={9} lg={8} md={6} xs={6} sm={6}>
                              <div className="">
                                <p className="mini-text">
                                  {order?.payment_mode}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">Quantity:</p>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">
                                  {order?.product_quantity?.quantity}{" "}
                                  {order?.product_quantity?.unit?.name}
                                </p>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">Qty. Of Pack:</p>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                              <div className="orderlist_div">
                                <p className="mini-text">{order?.quantity}</p>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                              <div className="orderlist_div">
                                <p className="price-text">Total amount:</p>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                              <div className="orderlist_div">
                                <p className="price-text">₹ {order?.total}</p>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                              <div className="orderlist_div">
                                <p className="price-text">Status:</p>
                              </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                              <div className="orderlist_div">
                                <p className="price-text">
                                  {" "}
                                  {order?.order_payment?.status
                                    ? "Paid"
                                    : "Unpaid"}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* {Order_Status.delivered == order.order_status_id && ( */}
                  <div className="ratemain">
                    <div
                      className="ratingmainss"
                      //   onClick={() => setSuccessfullModalShow(true)}
                    >
                      <p className="mini-text mb-0 me-2 text-decoration-underline">
                        Rate us:
                      </p>

                      {order?.order_rating ? (
                        <Rating value={order?.order_rating?.ratings} readOnly />
                      ) : (
                        <Rating onClick={() => handleSubmit(order?.id)} />
                      )}
                    </div>
                    <p className="mini-text mb-0 deliverd">
                      {order.order_status?.name +
                        " Successfully On " +
                        formatDate(order.updatedAt)}

                      {/* Delivered Successfully On 20 August */}
                    </p>
                  </div>
                  {/* )} */}
                </div>
                {/* <div className="order-Steps">
                  <Row>
                    <Col xxl={6}>
                      {!(Order_Status.delivered == order.order_status_id) && (
                        <div className="stepper-div">
                          <ProgressBar percent={stepPercentage}>
                            {orderStatus?.map((status, index) => (
                              <Step key={index}>
                                {({ accomplished, index }) => (
                                  <div
                                    className={`indexedStep ${
                                      order?.order_status_id == status?.id
                                        ? "accomplished"
                                        : null
                                    }`}
                                  >
                                    <div className="range-text-div">
                                      <h6 className="mini-text">
                                        {status?.name}
                                      </h6>
                                      <p className="date">
                                        {order?.order_status_histories[index]
                                          ?.createdAt &&
                                          formatDate(
                                            order?.order_status_histories[index]
                                              ?.createdAt
                                          )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </Step>
                            ))}
                          </ProgressBar>
                        </div>
                      )}
                    </Col>
                    <Col xxl={6}>
                      <div className="Button-Main">
                        <Link to={`/cancel-order/${order?.id}`}>
                          <Button_Common
                            className="cancel text me-4"
                            text="CANCEL"
                          />
                        </Link>
                        {Order_Status.delivered == order.order_status_id && (
                          <Link to={`/return-order/${order?.id}`}>
                            <Button_Common
                              className="return text"
                              text="RETURN"
                            />
                          </Link>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div> */}
                <div className="order-border"></div>
              </React.Fragment>
            ))}
            {/* <div className="ordermain_divv">
              <div className="oreder_main">
                <Row>
                  <Col
                    xxl={3}
                    xl={3}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mb-2"
                  >
                    <div className="image">
                      <div className="orderlist_imgdiv">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/Orders/balsam-tolu.png"
                          }
                          className="orderlist_img"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xxl={9}
                    xl={9}
                    lg={8}
                    md={12}
                    sm={12}
                    className="my-auto mb-2"
                  >
                    <div className="order_data">
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Product Name:</p>
                          </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">
                              Balsam Tolu (Reconstitution)
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Name of address:</p>
                          </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">
                              Akshya Nagar 1st Block 1st Cross, Rammurthy nagar,
                              Bangalore-560016
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Payment Method:</p>
                          </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={8} md={6} xs={6} sm={6}>
                          <div className="">
                            <p className="mini-text">Online</p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Quantity:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">1 kg</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">Qty. Of Pack:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                          <div className="orderlist_div">
                            <p className="mini-text">1</p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">Total amount:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">₹. 1,234.00</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">Status:</p>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                          <div className="orderlist_div">
                            <p className="price-text">Paid</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="ratemain">
                <div className="ratingmainss">
                  <p className="mini-text mb-0 me-2 text-decoration-underline">
                    Rate us:
                  </p>
                  <Rating />
                </div>
                <p className="mini-text mb-0 deliverd">
                  Delivered Successfully On 20 August
                </p>
              </div>
            </div>
            <div className="order-Steps">
              <Row>
                <Col xxl={6}>
                  <div className="stepper-div">
                    <ProgressBar percent={stepPercentage}>
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="mini-text">Order Placed</h6>
                              <p className="date">20 Dec</p>
                            </div>
                          </div>
                        )}
                      </Step>
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="mini-text">Processing</h6>
                              <p className="date">20 Dec</p>
                            </div>
                          </div>
                        )}
                      </Step>
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="mini-text">Shipped</h6>
                              <p className="date">Est. 30 Dec</p>
                            </div>
                          </div>
                        )}
                      </Step>

                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="mini-text">Delivered</h6>
                              <p className="date">Est. 30 Dec</p>
                            </div>
                          </div>
                        )}
                      </Step>
                    </ProgressBar>
                  </div>
                </Col>
                <Col xxl={6}>
                  <div className="Button-Main">
                    <Link to="/cancel-order">
                      <Button_Common
                        className="cancel text me-4"
                        text="CANCEL"
                      />
                    </Link>
                    <Link to="/cancel-order">
                      <Button_Common className="return text" text="RETURN" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="order-border"></div> */}
            {/* {orders.map((item, index) => (
              <div className="ordermain_divv" key={index}>
                <div className="order_main">
                  <Row>
                    <Col
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      className="mb-2"
                    >
                      <div className="image">
                        <div className="orderlist_imgdiv">
                          <img
                            src={item.image}
                            className="orderlist_img"
                            alt="Product"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      xxl={9}
                      xl={9}
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      className="my-auto mb-2"
                    >
                      <div className="order_data">
                        <Row>
                          <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">Product Name:</p>
                            </div>
                          </Col>
                          <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">{item.name}</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">Name of address:</p>
                            </div>
                          </Col>
                          <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">{item.address}</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">Payment Method:</p>
                            </div>
                          </Col>
                          <Col xxl={9} xl={9} lg={8} md={6} xs={6} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">{item.method}</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">Quantity:</p>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">{item.quantity}</p>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">Qty. Of Pack:</p>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                            <div className="orderlist_div">
                              <p className="mini-text">{item.pack}</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                            <div className="orderlist_div">
                              <p className="price-text">Total amount:</p>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                            <div className="orderlist_div">
                              <p className="price-text">₹. 1,234.00</p>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                            <div className="orderlist_div">
                              <p className="price-text">Status:</p>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                            <div className="orderlist_div">
                              <p className="price-text">Paid</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="ratemain">
                  <div
                    className="ratingmainss"
                    onClick={() => setSuccessfullModalShow(true)}
                  >
                    <p className="mini-text mb-0 me-2 text-decoration-underline">
                      Rate us:
                    </p>
                    <Rating />
                  </div>
                  <p className="mini-text  delivered mb-0">
                    Delivered Successfully On 20 August
                  </p>
                </div>
                <div className="order-border"></div>
              </div>
            ))} */}
          </div>
        </Container>
      </section>
      <Rate_Us_Modal
        show={modalSuccessfullShow}
        getData={getData}
        onHide={() => setSuccessfullModalShow(false)}
      />
    </>
  );
};

export default Cancel;
