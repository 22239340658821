import React, { useState } from "react";
import "./Faqbanner.css";
import { Container } from "react-bootstrap";
import Banner_Heading_Text from "../../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
const Faqbanner = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "FAQ", link: "/faq", className: "active" },
  ]);

  return (
    <>
      <section className="faq">
        <div className="Main-Faq">
          <Container></Container>
          <div className="Fa-banner">
            <img
              src={
                process.env.PUBLIC_URL + "/assests/AboutUs/Banner/aboutus.png"
              }
              className="banner"
              alt="Mansa Nutri Logo"
            />
          </div>
          <Container>
            <div className="Content">
              <Banner_Heading_Text
                headingText={""}
                breadcrumbItems={breadcrumbItems}
              />
              <h1 className="headingtext">FAQs</h1>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Faqbanner;
