import React, { useContext, useEffect, useState } from "react";
import "./Dignitaries.css";
import { Col, Container, Row } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { text } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faSquareFacebook,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { getDignitaries } from "../../../utils/apis/about";
import { Context } from "../../../utils/context";
function Digitiry() {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getDignitaries();
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  //   const cardsDetails = [
  //     {
  //       name: "Mr. Om Prakash",
  //       place: "CEO",
  //       text:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //       image: process.env.PUBLIC_URL + "/assests/AboutUs/Mission/d1.png",
  //     },
  //     {
  //       name: "Mr. Ravi Prakash",
  //       place: "Managing Director",
  //       text:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //       image: process.env.PUBLIC_URL + "/assests/AboutUs/Mission/d2.png",
  //     },
  //     {
  //       name: "Mr. Om Prakash",
  //       place: "CEO",
  //       text:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //       image: process.env.PUBLIC_URL + "/assests/AboutUs/Mission/d1.png",
  //     },
  //     {
  //       name: "Mr. Ravi Prakash",
  //       place: "Managing Director",
  //       text:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //       image: process.env.PUBLIC_URL + "/assests/AboutUs/Mission/d2.png",
  //     },
  //     {
  //       name: "Mr. Om Prakash",
  //       place: "CEO",
  //       text:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //       image: process.env.PUBLIC_URL + "/assests/AboutUs/Mission/d1.png",
  //     },
  //     {
  //       name: "Mr. Ravi Prakash",
  //       place: "Managing Director",
  //       text:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //       image: process.env.PUBLIC_URL + "/assests/AboutUs/Mission/d2.png",
  //     },
  //   ];
  return (
    <>
      <section className="Dignitaries">
        <Container>
          <div className="digitiry_div">
            <div className="text-center ">
              <h2 className="big-title">Our Dignitaries</h2>
            </div>

            <div className="ourdigi">
              <div className="digimainnn">
                <div className="bgrcolrr"></div>
                <div className="swippingg">
                  <Row>
                    <Col
                      xxl={8}
                      xl={8}
                      lg={9}
                      md={10}
                      sm={11}
                      xs={11}
                      className="mx-auto"
                    >
                      <Swiper
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                          486: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 60,
                          },
                          992: {
                            slidesPerView: 2,
                            spaceBetween: 80,
                          },
                          1200: {
                            slidesPerView: 2,
                            spaceBetween: 100,
                          },
                          1400: {
                            slidesPerView: 2,
                            spaceBetween: 100,
                          },
                          1900: {
                            slidesPerView: 2,
                            spaceBetween: 100,
                          },
                        }}
                        className="mySwiper"
                      >
                        {data?.map((item, index) => (
                          <SwiperSlide key={index}>
                            <div className="cardswipe">
                              <div className="main">
                                <div className="ourimgdiv">
                                  <img
                                    src={IMG_URL + item?.image}
                                    className="ourimg"
                                    alt={item?.name}
                                  />
                                  <div className="overlay"></div>
                                </div>
                              </div>
                              <div className="text_divs">
                                <p className="price-text mb-0">{item?.name}</p>
                                <p className="mini-text-p  mb-2">
                                  {item?.position}
                                </p>
                                <p className="texttext mb-0">
                                  {item?.short_description}
                                </p>
                                <div className="icon-holder">
                                  {item?.dignitaries_social_media?.map(
                                    (social, index) => (
                                      <div
                                        className="icon-img-holder"
                                        key={index}
                                      >
                                        <img
                                          src={
                                            IMG_URL +
                                            social?.social_medium?.logo
                                          }
                                          className="icon-img ficnn1 me-2"
                                          alt="icon-img"
                                        />

                                        <img
                                          src={
                                            IMG_URL +
                                            social?.social_medium?.logo
                                          }
                                          className="icon-img ficnn2 me-2"
                                          alt="icon-img"
                                        />
                                      </div>
                                    )
                                  )}

                                  {/* <div className="icon-img-holder">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assests/AboutUs/logos/facebook1.png"
                                      }
                                      className="icon-img ficnn1 me-2"
                                      alt="icon-img"
                                    />

                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assests/AboutUs/logos/facebook2.png"
                                      }
                                      className="icon-img ficnn2 me-2"
                                      alt="icon-img"
                                    />
                                  </div>

                                  <div className="icon-img-holder">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assests/AboutUs/logos/twitter1.png"
                                      }
                                      className="icon-img ficnn1 me-2"
                                      alt="icon-img"
                                    />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assests/AboutUs/logos/twitter2.png"
                                      }
                                      className="icon-img ficnn2 me-2"
                                      alt="icon-img"
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Digitiry;
