import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const AddAddress = async (data) => {
  try {
    const res = await postData(`/address`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const EditAddress = async (id, data) => {
  try {
    const res = await postData(`/address/${id}`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const GetAllAddress = async () => {
  try {
    const res = await getData(`/address`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const GetAddress = async (id) => {
  try {
    const res = await getData(`/address/${id}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const DeleteAddress = async (id) => {
  try {
    const res = await deleteData(`/address/${id}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const DefaultAddress = async (id) => {
  try {
    const res = await getData(`/address/default/${id}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const SeletedAddress = async (id) => {
  try {
    const res = await getData(`/address/seleted`);
    return res;
  } catch (error) {
    console.error(error);
  }
};
