import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductCards from "../CommonComponents/ProductCards/ProductCards";
import "./Wishlist.css";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import { Context } from "../../utils/context";
function Wishlist() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "WISHLIST", link: "/wishlist", className: "active" },
    ,
  ]);
  const products = [
    {
      image: process.env.PUBLIC_URL + "/assests/ProductCards/p1.png",
      title: "Black Pepper Essential Oils ",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/ProductCards/p2.png",
      title: "Bergamot Essential Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
      title: "Bitter Orange Oil ",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/ProductCards/p4.png",
      title: "Carrot Seed Oil ",
      price: "00.00",
    },
  ];

  // ----------------------------------------------------------------
  const { wishlist, IMG_URL } = useContext(Context);

  return (
    <>
      <section className="wishlist_section">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="wishlistmain">
            <Row>
              {wishlist?.map((item, index) => (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} key={index}>
                  <div className="wishlisstcard">
                    <ProductCards
                      id={item?.product_id}
                      image={IMG_URL + item?.product?.image}
                      title={item?.product?.name}
                      price={
                        item?.product?.product_quantities.find(() => true)
                          ?.price
                      }
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Wishlist;
