import React, { useContext, useEffect, useState } from "react";
import "./TrendingProducts.css";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import ProductCards from "../../CommonComponents/ProductCards/ProductCards";
import Pagination from "react-bootstrap/Pagination";
import Paginations from "../../CommonComponents/Paginations/Paginations";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";
import { getTrendingProduct } from "../../../utils/apis/home";
import CustomPagination from "../../CommonComponents/Paginations/CustomPagination";

const TrendingProducts = () => {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState({});
  const [active, setActive] = useState(null);

  const getData = async (id = null) => {
    const res = await getTrendingProduct(id);
    if (id) {
      setActive(id);
    } else {
      const defaultCategoryId =
        res?.data?.data?.category_id || res?.data?.categoryNames?.[0]?.id;
      setActive(defaultCategoryId);
    }
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* TrendingProducts css started */}

      <section className="TrendingProducts">
        <Container>
          <p class="text">TRENDING PRODUCT</p>
          <h1 className="mid-title">Floral's Hot Selling Products</h1>
          <div className="Tab-Section">
            <Tab.Container id="left-tabs-example" activeKey={active}>
              <div className="d-flex flex-column  ">
                <Nav variant="pills" className="justify-content-center">
                  {data?.categoryNames?.map((data, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        eventKey={data?.id}
                        onClick={() => getData(data?.id)}
                      >
                        {data?.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content>
                  {data?.categoryNames?.map((val, index) => (
                    <Tab.Pane eventKey={val.id} key={index}>
                      <Row className="">
                        {data?.data?.category?.products?.map((item, index) => (
                          <Col
                            xxl={3}
                            xl={4}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={6}
                            key={index}
                          >
                            <ProductCards
                              id={item?.id}
                              image={IMG_URL + item.image}
                              title={item.name}
                              price={
                                item?.product_quantities.find(() => true)?.price
                              }
                            />
                          </Col>
                        ))}
                        {/* <Paginations /> */}

                        <CustomPagination
                          currentPage={data?.page}
                          totalPages={data?.totalPages}
                          handlePageChange={(page) =>
                            getData(data?.data?.category_id, page)
                          }
                        />
                      </Row>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Container>
      </section>

      {/* TrendingProducts css End */}
    </>
  );
};

export default TrendingProducts;
