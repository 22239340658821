import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Button_Common/Button_Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import "./Modals.css";
import { Login } from "../../../../utils/apis/login/Login";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import { loginCart } from "../../../../utils/apis/addToCart/addToCart";
import Email_and_Password from "./Email_and_Password";
import OTP_Login from "./OTP_Login";
function Login_Modal(props) {
  // const [passwordVisible, setPasswordVisible] = useState(false);
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  // const togglePasswordVisibility = () => {
  //   setPasswordVisible(!passwordVisible);
  // };

  const handleLoginClick = () => {
    props.setSuccessfullModalShow(true);
    props.onHide();

    setTimeout(() => {
      props.setSuccessfullModalShow(false);
    }, 1000);
  };
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (modalSuccessfullShow) {
      timer = setTimeout(() => {
        setSuccessfullModalShow(false);

        navigate("/");
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [modalSuccessfullShow]);

  const handleCloseModal = () => {
    props.onHide(); // Close the modal
  };

  // ------------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0">
                  <div className="col-xl-12 col-md-12 col-sm-12">
                    <div className="log_in_form">
                      <div className="tabs-radio-holder">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <Row>
                            <Col sm={12}>
                              <Nav variant="pills" className="tabs-holder">
                                <Nav.Item>
                                  <Nav.Link eventKey="first">
                                    Login with Password
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second">
                                    Login with OTP
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>
                            <Col sm={12}>
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  <Email_and_Password
                                    handleLoginClick={handleLoginClick}
                                    setCreatAccountModalShow={
                                      props.setCreatAccountModalShow
                                    }
                                    onHide={props.onHide}
                                    setForgetModalShow={
                                      props.setForgetModalShow
                                    }
                                  />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  <OTP_Login
                                    handleLoginClick={handleLoginClick}
                                    setCreatAccountModalShow={
                                      props.setCreatAccountModalShow
                                    }
                                    onHide={props.onHide}
                                    setForgetModalShow={
                                      props.setForgetModalShow
                                    }
                                  />
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>

                      {/* <div className="log_in_with">
                        <p>or Login with social accounts</p>
                      </div>

                      <div className="social_img_holder">
                        <img
                          className="social_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/log_in/google.png"
                          }
                          alt="social_img"
                        />
                      </div> */}
                      <Link to="/" onClick={handleCloseModal}>
                        <div className="back_to_span">
                          <p>Back to Home {">"}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Success Modal */}
      <Successfull_Modal
        show={props.modalSuccessfullShow}
        onHide={() => props.setSuccessfullModalShow(false)}
        message="Login successful!"
      />
    </>
  );
}

export default Login_Modal;
