import React, { useState } from "react";
import "./Privacy_Policy_Banner.css";
import Breadcrumbs from "../../CommonComponents/Breadcrumbs/Breadcrumbs";

function Privacy_Policy_Banner() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "PRIVACY POLICY", link: "/privacy-policy", className:"active" },
    ,
  ]);

  return (
    <>
      <section className=" Privacy_Policy Privacy_Policy_Banner">
        <div className="Banner_holder">
          <div className="container">
            <div className="text-holder ">
              <div>
                <Breadcrumbs items={breadcrumbItems} />
              </div>
              <h1 className="title">Privacy Policy</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy_Policy_Banner;
