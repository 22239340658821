import React, { useContext, useEffect, useState } from "react";
import "./QualityProducts.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { getSpecifications } from "../../../utils/apis/about/index";

const QualityProducts = () => {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getSpecifications();
    setData(res?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="QualityProducts">
        <Container>
          <div className="QualityProducts-Main">
            <div className="content-Products">
              {data?.map((val, index) => (
                <React.Fragment key={index}>
                  <div className="product">
                  <div className="image-quality">
                  <img src={IMG_URL + val?.logo} className="icon" />
                  </div>
                  
                    <div className="Content">
                      <p className="sub-text">{val?.name}</p>
                      <p className="mini-text">
                        {htmlToReactParser.parse(val?.description) || ""}
                      </p>
                    </div>
                  </div>
                  {data?.length >= index + 2 && (
                    <div className="border-main"></div>
                  )}
                </React.Fragment>
              ))}

              {/* <div className="product ">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/AboutUs/logos/product-return.png"
                  }
                  className="icon"
                />
                <div className="Content">
                  <p className="sub-text">Return</p>
                  <p className="mini-text">
                    Return product within 3 days<br></br> for any product you
                    buy
                  </p>
                </div>
              </div>

              <div className="border-main"></div>

              <div className="product">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/AboutUs/logos/technical-support.png"
                  }
                  className="icon"
                />
                <div className="Content">
                  <p className="sub-text">Online Support</p>
                  <p className="mini-text">
                    We ensure the product that <br></br>you can trust easily
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default QualityProducts;
