import React from "react";
import FaqTabs from "./FaqTabs/FaqTabs";
import Faqbanner from "./FaqBanner/Faqbanner";

function Faqs() {
  return (
    <>
      <Faqbanner />
      <FaqTabs />
    </>
  );
}

export default Faqs;
