import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Button_Common/Button_Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import "./Modals.css";
import { Login, MobileLogin } from "../../../../utils/apis/login/Login";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import { loginCart } from "../../../../utils/apis/addToCart/addToCart";
import { RegxExpression } from "../../../../utils/common";
import {
  MobileOTPSend,
  NewMobileOTPSend,
} from "../../../../utils/apis/VerifyOTP/VerifyOTP";
import { postData } from "../../../../utils/api";
const OTP_Login = (props) => {
  const { signin, setSignin, Cookies } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await MobileLogin(data);
    if (res?.success) {
      await Cookies.set("mansa_nutri_security", res?.data?.token);
      reset();
      clearErrors();
      setSignin(true);
      await loginCart();
      props.handleLoginClick();
    } else {
      if (res?.message) {
        if (res?.message?.contact_no) {
          setError("contact_no", { message: res?.message?.contact_no });
        }
        if (res?.message?.otp) {
          setError("otp", { message: res?.message?.otp });
        }
      }
    }
  };

  // const handleVerify = async () => {
  //   console.log("Verify OTP Clicked");
  // };

  const [isVerified, setIsVerified] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const handleSendOTP = async () => {
    if (getValues("contact_no").length == 10) {
      clearErrors("contact_no");
      const res = await MobileOTPSend({
        contact_no: getValues("contact_no"),
      });
      if (res?.success) {
        setIsVerified(true);
        setIsResendEnabled(false);
        setTimer(60);
      } else {
        setError("contact_no", { message: res?.message });
      }
    } else {
      setError("contact_no", { message: "Enter Valid Mobile No." });
    }
  };
  useEffect(() => {
    setIsVerified(false);
  }, []);

  const deletOtp = async () => {
    if (getValues("contact_no")) {
      let sanitizedContactNo = getValues("contact_no").replace(/^(\+91)?/, "");
      // Remove any dashes (-)
      sanitizedContactNo = sanitizedContactNo.replace(/-/g, "");
      const res = await postData(`/without-login/verify-otp/delete`, {
        contact_no: sanitizedContactNo,
      });
      if (res?.success) {
        console.log("OTP DELETED", res?.data);
      } else {
        console.error("Failed to delete OTP:", res?.message);
      }
    } else {
      console.error("Valid Contact number is required");
      // setContactError("Contact number is required");
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      deletOtp();
      setIsResendEnabled(true);
    }
  }, [timer]);

  return (
    <>
      <div className="LogIn">
        <div className="bg-color ">
          <div className="log_in_form">
            <Form>
              <div className="verify-field-holder">
                <Form.Group className="mb-2" controlId="">
                  <Form.Control
                    type="text"
                    className={classNames("", {
                      "is-invalid": errors?.contact_no,
                      "is-valid": getValues("contact_no"),
                    })}
                    name="contact_no"
                    placeholder="Enter Mobile Number"
                    {...register("contact_no", {
                      required: "Field is required",
                      minLength: {
                        value: 10,
                        message: "Number should be at least 10 characters",
                      },
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.phone.test(event.key)) {
                        if (event.key !== "Backspace") {
                          event.preventDefault();
                        }
                      }
                    }}
                    maxLength={10}
                  />
                </Form.Group>{" "}
                {!isVerified && (
                  <div className="verify-text-holder" onClick={handleSendOTP}>
                    <span>Send OTP</span>
                  </div>
                )}
                {errors?.contact_no && (
                  <sup className="text-danger">
                    {errors?.contact_no?.message}
                  </sup>
                )}{" "}
              </div>
              {isVerified && (
                <div className="verify-field-holder">
                  <Form.Group
                    className="password-field-holder mb-2 field-holder"
                    controlId=""
                  >
                    <Form.Control
                      type="text"
                      className={classNames("", {
                        "is-invalid": errors?.otp,
                        "is-valid": getValues("verifed"),
                      })}
                      name="otp"
                      placeholder="Enter OTP"
                      {...register("otp", {
                        required: "OTP is required",
                        minLength: {
                          value: 4,
                          message: "Number should be at least 4 characters",
                        },
                      })}
                      onKeyDown={(event) => {
                        if (!RegxExpression.phone.test(event.key)) {
                          if (event.key !== "Backspace") {
                            event.preventDefault();
                          }
                        }
                      }}
                      maxLength={4}
                    />
                  </Form.Group>
                  <div className="otp-timer ">
                    {isResendEnabled ? (
                      <span
                        className="text-green-holder"
                        onClick={handleSendOTP}
                      >
                        Resend OTP
                      </span>
                    ) : (
                      <span>Enter OTP in {timer} seconds</span>
                    )}
                  </div>
                  {errors?.otp && (
                    <sup className="text-danger">{errors?.otp?.message}</sup>
                  )}
                </div>
              )}
              <Form.Text
                className="Forgot_password"
                onClick={() => {
                  props.setForgetModalShow(true);
                  props.onHide();
                }}
              >
                Forgot password?
              </Form.Text>
              <div className="btn-holder">
                <Common_Button
                  className="SubmitBtn green text"
                  text="Login"
                  onClick={handleSubmit(onSubmit)}
                  // onClick={handleLoginClick}
                />
              </div>
              <div className="create_account ">
                <div className="login_text">
                  <p>
                    You don't have an account?
                    <span
                      className="login_text"
                      onClick={() => {
                        props.setCreatAccountModalShow(true);
                        props.onHide();
                      }}
                    >
                      Create account
                    </span>
                  </p>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTP_Login;
