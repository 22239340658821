import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./OurMission.css";
import { Context } from "../../../utils/context";
import { getOurMissions } from "../../../utils/apis/about";

const OurMission = () => {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getOurMissions();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="OurMission">
        <Container>
          {data?.map((val, index) =>
            index % 2 == 0 ? (
              <div className="main-vission" key={index}>
                <Row className="vission">
                  <Col xxl={7} xl={7} lg={7}>
                    <div className="Content-mission">
                      <h1 className="big-title">
                        <span className="our">{val?.name}</span>
                      </h1>
                      <p className="mini-text-p mt-3">
                        {htmlToReactParser.parse(val?.description) || ""}
                      </p>
                    </div>
                  </Col>
                  <Col xxl={5} xl={5} lg={5}>
                    <div className="OurMission-Main">
                      <div className="Misiion-Image">
                        <img src={IMG_URL + val?.logo} className="mission" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="main-mission">
                <Row className="vission">
                  <Col xxl={5} xl={5} lg={5}>
                    <div className="OurMission-Main2">
                      <div className="Misiion-Image">
                        <img src={IMG_URL + val?.logo} className="mission" />
                      </div>
                    </div>
                  </Col>
                  <Col xxl={7} xl={7} lg={7}>
                    <div className="Content-mission">
                      <h1 className="big-title">
                        <span className="our">{val?.name}</span>
                      </h1>
                      <p className="mini-text-p mt-3">
                        {htmlToReactParser.parse(val?.description) || ""}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          )}
          {/* <div className="main-vission">
            <Row className="vission">
              <Col xxl={7} xl={7} lg={7}>
                <div className="Content-mission">
                  <h1 className="big-title">
                    <span className="our">Our</span> Mission
                  </h1>
                  <p className="mini-text-p mt-3">
                    Our mission is to enhance well-being and promote a healthier
                    lifestyle through the power of natural essential oils. We
                    are committed to sourcing the highest quality, sustainably
                    harvested ingredients and creating pure, potent products
                    that provide therapeutic benefits. By prioritizing
                    environmental responsibility and transparency, we aim to
                    inspire trust and empower our customers to embrace the
                    healing potential of nature
                  </p>
                </div>
              </Col>
              <Col xxl={5} xl={5} lg={5}>
                <div className="OurMission-Main">
                  <div className="Misiion-Image">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assests/AboutUs/Mission/mission.png"
                      }
                      className="mission"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div> */}

          {/* <div className="main-mission">
            <Row className="vission">
              <Col xxl={5} xl={5} lg={5}>
                <div className="OurMission-Main2">
                  <div className="Misiion-Image">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assests/AboutUs/Mission/vission.png"
                      }
                      className="mission"
                    />
                  </div>
                </div>
              </Col>
              <Col xxl={7} xl={7} lg={7}>
                <div className="Content-mission">
                  <h1 className="big-title">
                    <span className="our">Our</span> Vision
                  </h1>
                  <p className="mini-text-p mt-3">
                    Our vision for the essential oil industry is to lead a
                    sustainable and innovative future where the highest quality,
                    ethically sourced essential oils enhance the well-being of
                    individuals worldwide. We aim to set the standard for purity
                    and potency while fostering eco-friendly practices that
                    protect our planet. By empowering communities through fair
                    trade and supporting holistic health, we envision a world
                    where essential oils are integral to everyday wellness and
                    harmony
                  </p>
                </div>
              </Col>
            </Row>
          </div> */}
        </Container>
      </section>
    </>
  );
};

export default OurMission;
