import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./OilCategory.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Navigation, Mousewheel, Keyboard } from "swiper/modules";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { text } from "@fortawesome/fontawesome-svg-core";
import { getRandomCategory } from "../../../utils/apis/home";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";
const OilCategory = () => {
  const navigate = useNavigate();
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState({});

  const getData = async () => {
    const res = await getRandomCategory();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="OilCategory">
        <Container>
          <p class="text">{data?.category?.name}</p>
          <h1 className="mid-title">{data?.category?.short_description}</h1>
          <Swiper
            className="mySwiper"
            cssMode={true}
            // navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              380: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              485: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              575: {
                slidesPerView: 2,
                spaceBetween: 30,
              },

              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              2000: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {data?.products?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="Oil-Main-section">
                  <div className="categories">
                    <img src={IMG_URL + item?.image} className="oil-image" />
                  </div>
                  <div className="oils-content">
                    <div className="oil-background">
                      <button
                        className="btn btn-reverse btn-arrow"
                        onClick={() => {
                          navigate(`/product-detail/${item.id}`);
                        }}
                      >
                        <span>
                          {item?.name}
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="icon"
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default OilCategory;
