import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import { GetAllAddress, SeletedAddress } from "../../utils/apis/address";
const Payment = (props) => {
  // ----------------------------------------------------------------
  const [Address, SetAddress] = useState([]);
  const getData = async () => {
    const res = await SeletedAddress();
    SetAddress(res?.data);
    props.setValue("address_id", res?.data?.id);
    props.setValue("address", res?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const SaveNext = async () => {
    // console.log(props.getValues("payment_mode"));
    if (props.getValues("payment_mode")) {
      props.nextStep();
    }
  };
  return (
    <>
      <section className="checkout-inner-steps">
        <div className="Address">
          <div className="adresmain">
            {/* <h3>Thank You for Trusting Us</h3> */}
            <div className="adresss">
              {/* <p className="primary">Primary Contact</p> */}
              <div className="name">
                <Row>
                  <Col xxl={3} xl={3} lg={4} md={12} className="mb-2">
                    <p className="text main">Name *</p>
                  </Col>
                  <Col xxl={9} xl={9} lg={8} md={12} className="mb-2">
                    <p className="text">
                      {Address?.user?.first_name} {Address?.user?.last_name}
                    </p>
                  </Col>
                  <Col xxl={3} xl={3} lg={4} md={12}>
                    <p className="text main">Address*</p>
                  </Col>
                  <Col xxl={9} xl={9} lg={8} md={12}>
                    <p className="text">{`${Address?.add_type} ${Address?.address} ${Address?.landmark} - ${Address?.zip_code}`}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="Choose_Payment_Option">
            <div className="heading-holder">
              <h4 className="price-text">Choose Payment Option</h4>
            </div>

            <div>
              <Form>
                <div className="radio-btn-input-holder">
                  <Form.Check
                    type="radio"
                    name="payment_mode"
                    {...props.register("payment_mode", {
                      required: "payment type is required",
                    })}
                    value="cod"
                    label="Cash on Delivery"
                  />

                  <Form.Check
                    type="radio"
                    name="payment_mode"
                    {...props.register("payment_mode", {
                      required: "payment type is required",
                    })}
                    value="online"
                    label="Online"
                  />
                </div>
              </Form>
            </div>
            {/* <Form>
              <div className="payment_method_card bodr_bottom">
                <div className="heading-holder">
                  <h4>Pay with credit card</h4>
                </div>

                <div className="paycredit">
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assests/Address/visa.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/Address/mastercard.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment_method_card">
                <div className="heading-holder">
                  <h4>Pay with UPI</h4>
                </div>

                <div className="paycredit">
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assests/Address/gpay.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>

                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assests/Address/paytm.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>

                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="online"
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assests/Address/paytm.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form> */}
          </div>
          <div className="  apply_now_btn">
            <Button_Common
              onClick={props.prevStep}
              className="back-btn me-3 text brown"
              text="BACK"
            >
              Back
            </Button_Common>

            <Button_Common
              type="button"
              onClick={SaveNext}
              // onClick={props.nextStep}
              className="tabs-btn text green"
              text="SAVE & NEXT"
            >
              Next
            </Button_Common>
          </div>
        </div>

        {/* <div className="main-btn-div ">
            <Common_Button
              onClick={handlePrevious}
              text="Back"
              className=" back-btn"
            />

            <Common_Button
              onClick={handleNext}
              text="Save & Next"
              className=" save-btn"
            />
          </div> */}
      </section>
    </>
  );
};

export default Payment;
