import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ProductDetailsPage.css";
import Banner_Heading_Text from "../../Banner_Heading_Text/Banner_Heading_Text";
import IncrementDecrement from "../../IncreamentDecreament/IncreamentDecreament";
import Heart_Component from "../../Heart_Component/Heart_Component";
import ButtonCommon from "../../Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Bottom_Successfull_Modal from "../../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { useNavigate } from "react-router-dom";
import Get_A_Quote from "../../Common_Modal/Get_A_Quote/Get_A_Quote";
import {
  AddToCart,
  updateCartCount,
} from "../../../../utils/apis/addToCart/addToCart";
import { Context } from "../../../../utils/context";
function ProductDetailsPage(props) {
  const { data, IMG_URL } = props;
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "PRODUCTS", link: "/product" },
    {
      text: `${data?.name}`,
      link: "",
      className: "active",
    },
  ]);

  // const [modalShow, setModalShow] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (modalShow) {
      const timer = setTimeout(() => {
        setModalShow(false);
        navigate("/cart");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [modalShow, navigate]);

  // ------------------------------------------------------------------------------------------------
  const { signin, setCart, setCartCount, cart, wishlist } = useContext(Context);
  const [variants, setVariants] = useState([]);
  useEffect(() => {
    setBreadcrumbItems([
      { text: "HOME", link: "/" },
      { text: "PRODUCTS", link: "/product" },
      {
        text: `${data?.name}`,
        link: "",
        className: "active",
      },
    ]);
    SETValuesVarinat();
  }, [data, cart]);

  // const SETValuesVarinat = async () => {
  //   const demo = [];
  //   for (const val of data?.product_quantities) {
  //     let temp = {
  //       name: data.name,
  //       short_description: data.short_description,
  //       product_id: data.id,
  //       image: IMG_URL + data.image,
  //       variant_id: val.id,
  //       price: val.price,
  //       v_quantity: val.quantity,
  //       unit: val.unit?.name,
  //     };

  //     const existingItemIndex = cart.findIndex(
  //       (item) =>
  //         item.product_id === temp.product_id &&
  //         item.variant_id === temp.variant_id
  //     );
  //     if (existingItemIndex === -1) {
  //       temp.quantity = 0;
  //     } else {
  //       temp.quantity = cart[existingItemIndex].quantity;
  //     }

  //     demo.push(temp);
  //   }

  //   setVariants(demo);
  // };

  const SETValuesVarinat = async () => {
    const demo = data?.product_quantities.map((val) => {
      const existingItemIndex = cart.findIndex(
        (item) => item.product_id === data.id && item.variant_id === val.id
      );

      return {
        id: existingItemIndex === -1 ? 0 : Number(cart[existingItemIndex].id),
        name: data.name,
        short_description: data.short_description,
        product_id: data.id,
        image: IMG_URL + data.image,
        variant_id: val.id,
        price: val.price,
        v_quantity: val.quantity,
        unit: val.unit?.name,
        quantity:
          existingItemIndex === -1
            ? 0
            : Number(cart[existingItemIndex].quantity),
        status: 1,
        tax_percentage: data.tax_percentage,
      };
    });

    setVariants(demo);
  };

  const handleAddSmapleToCart = async () => {
    const temp = {
      name: data.name,
      short_description: data.short_description,
      product_id: data.id,
      image: IMG_URL + data.image,
      variant_id: data.product_quantities.find(() => true)?.id,
      price: data.product_quantities.find(() => true)?.price,
      v_quantity: data.product_quantities.find(() => true)?.quantity,
      unit: data.product_quantities.find(() => true)?.unit?.name,
      quantity: 1,
      tax_percentage: data.tax_percentage,
      status: 1,
    };
    await AddToCart(temp);
    setModalShow(true);
    updateCartCount(signin, setCart, setCartCount);
  };

  const handleAddToCart = async () => {
    for (const temp of variants) {
      if (temp.quantity > 0) {
        await AddToCart(temp);
        updateCartCount(signin, setCart);
      }
    }
    setModalShow(true);
  };

  const updateQuantity = async (index, quantity) => {
    const val = [...variants];
    val[index] = { ...val[index], quantity };
    setVariants(val);
  };

  return (
    <>
      <section className="ProductDetailsPage">
        <Container>
          <Banner_Heading_Text breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xxl={5} xl={5} lg={6}>
              <div className="Image-product">
                <img
                  src={IMG_URL + data?.image}
                  className="product-detail"
                  alt="Mansa Nutri Logo"
                />
                <div className="heart">
                  <Heart_Component
                    status={wishlist?.some(
                      (item) => item.product_id === data.id
                    )}
                    product_id={data.id}
                  />
                </div>
              </div>
            </Col>
            <Col xxl={7} xl={7} lg={6}>
              <div className="Product_detail-Content">
                <h1 className="product-detail">
                  {data?.name} ({data?.short_description})
                </h1>
                <div className="Quantity">
                  <div className="headins"></div>
                  <div className="Price">
                    <table class="table table-responsive">
                      <thead>
                        <tr>
                          <th className="pack">Quantity</th>
                          <th className="pack main">--------</th>
                          <th className="pack">Price Per Unit</th>
                          <th className="pack">Qty. of Pack</th>
                        </tr>
                      </thead>
                      <tbody>
                        {variants?.map((val, index) => (
                          <tr key={index}>
                            <td className="text">
                              {val?.v_quantity}
                              {val?.unit}
                            </td>
                            <td>
                              <div className="dashed"></div>
                            </td>
                            <td className="text">₹ {val?.price}</td>
                            <td className="text">
                              <IncrementDecrement
                                id={val.id}
                                quantity={val?.quantity}
                                index={index}
                                updateQuantity={updateQuantity}
                              />
                            </td>
                          </tr>
                        ))}

                        {/* <tr>
                          <td className="text">1kg</td>
                          <td>
                            <div className="dashed"></div>
                          </td>
                          <td className="text">₹ 00.00</td>
                          <td className="text">
                            <IncrementDecrement />
                          </td>
                        </tr>
                        <tr>
                          <td className="text">1kg</td>
                          <td>
                            <div className="dashed"></div>
                          </td>
                          <td className="text">₹ 00.00</td>
                          <td className="text">
                            <IncrementDecrement />
                          </td>
                        </tr>
                        <tr>
                          <td className="text">1kg</td>
                          <td>
                            <div className="dashed"></div>
                          </td>
                          <td className="text">₹ 00.00</td>
                          <td className="text">
                            <IncrementDecrement />
                          </td>
                        </tr>
                        <tr>
                          <td className="text">1kg</td>
                          <td>
                            <div className="dashed"></div>
                          </td>
                          <td className="text">₹ 00.00</td>
                          <td className="text">
                            <IncrementDecrement />
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>

                  <Row>
                    <Col xxl={8}>
                      <div className="cart-btn">
                        <ButtonCommon
                          text="ADD SAMPLE TO CART"
                          className="sample green"
                          onClick={handleAddSmapleToCart}
                          // onClick={() => setModalShow(true)}
                        />
                        <Bottom_Successfull_Modal
                          text="Sample added to cart successfully!"
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <ButtonCommon
                          text="ADD TO CART"
                          className="cart brown"
                          icon={faCartShopping}
                          onClick={handleAddToCart}
                          // onClick={() => setModalShow(true)}
                        ></ButtonCommon>
                      </div>
                    </Col>
                    <Col xxl={4}>
                      <div
                        className="main-text"
                        onClick={() => setSuccessfullModalShow(true)}
                      >
                        <p className="text">Get a Quote</p>
                      </div>
                      <Get_A_Quote
                        show={modalSuccessfullShow}
                        onHide={() => setSuccessfullModalShow(false)}
                        product_name={`${data?.name} (${data?.short_description})`}
                        product_id={data?.id}
                      />
                    </Col>
                  </Row>
                  <p className="mini-text">
                    Pricing is subject to change, & product availability is not
                    guaranteed.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ProductDetailsPage;
