import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const orderGet = async () => {
  try {
    const res = await getData(`/order/orderget`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const returnOrderGet = async () => {
  try {
    const res = await getData(`/order/order-return`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const CanceledOrderGet = async () => {
  try {
    const res = await getData(`/order/order-cancel`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const OrderCancel = async (data) => {
  try {
    const res = await postData(`/order/order-cancel`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const orderReturn = async (data) => {
  try {
    const res = await postData(`/order/order-return`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const orderRating = async (data) => {
  try {
    const res = await postData(`/order/order-rating`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const singleOrderGet = async (id) => {
  try {
    const res = await getData(`/order/orderget/${id}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const UserGet = async () => {
  try {
    const res = await getData(`/admin/usersingleget`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const UserEdit = async (data) => {
  try {
    const res = await postData(`/admin/userEdit`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const ChangePassword = async (data) => {
  try {
    const res = await postData(`/admin/change-password`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const ChangePasswordWL = async (data) => {
  try {
    const res = await postData(`/admin/change-password-wl`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const CheckExist = async (data) => {
  try {
    const res = await postData(`/admin/check-exist`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const emailOtpGet = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/email-otp-send`,
      data
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const emailOtpVerify = async (data) => {
  try {
    const res = await postData(`/without-login/otp/email-verify-otp`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};
