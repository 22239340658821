import React from 'react'
import "./Bottom_Successfull_Modal.css"
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

function Bottom_Successfull_Modal(props) {
    const { text } = props; // Extracting 'message' prop
   
  return (
    <>
      <Modal
      {...props}
      size="md"
      className='Bottom_Successfull_Modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Footer>
     {text}
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default Bottom_Successfull_Modal